import { observer } from 'mobx-react-lite';
import React from 'react';
import { useServices } from '../../hooks/useServices';
import { ComboBoxComponent } from '../shared/ComboBox/ComboBoxComponent';
import { PushToTalk } from '../shared/PushToTalk/PushToTalk';
import { VolumeBar } from '../shared/VolumeBar/VolumeBar';
import './Home.scss';

export const HomeView: React.FC = observer(() => {
  const { dataProvider, dataStore } = useServices();
  const store = dataStore.resolve();
  const { profileList, currentProfile, onProfileChange } = store;

  const onPushToTalkToggle = (e?: React.MouseEvent, state?: boolean) =>
    dataProvider.resolve().togglePushToTalk(state);

  const potListLength = (currentProfile?.volumePotList.length ?? 0) + 1;

  return (
    <div
      className={'home-grid'}
      style={{
        gridTemplateColumns: `repeat(${potListLength}, minmax(auto, 130px))`,
      }}
    >
      <div
        className={'home-profile-selector'}
        style={{
          gridArea: `1 / 1 / 2 / ${potListLength}`,
        }}
      >
        <ComboBoxComponent
          selectedKey={currentProfile?.id}
          options={profileList.map((profile) => ({ key: profile.id, text: profile.name }))}
          onChange={(e, option) => typeof option?.key === 'string' && onProfileChange(option.key)}
        />
      </div>
      <PushToTalk isToggle={!!currentProfile?.pushToTalk} onChange={onPushToTalkToggle} />
      {currentProfile?.volumePotList.map((volumeBar) => (
        <VolumeBar volumePot={volumeBar} key={volumeBar.id} />
      ))}
      {currentProfile?.masterPot && (
        <VolumeBar volumePot={currentProfile.masterPot} isMaster={true} />
      )}
    </div>
  );
});
