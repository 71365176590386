import { action, makeObservable } from 'mobx';

export abstract class EditableModelBase {
  public setProperty = <K extends keyof this>(key: K, value: this[K]): void => {
    const propType = typeof this[key];
    if (
      propType === 'string' ||
      propType === 'number' ||
      propType === 'boolean' ||
      propType === 'undefined'
    ) {
      this[key] = value;
    } else {
      throw new Error('Unable to set property. Only base types supported.');
    }
  };

  public constructor() {
    makeObservable(this, {
      setProperty: action,
    });
  }
}
