import { ActionButton, Label, Spinner, SpinnerSize, Text } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useServices } from '../../../hooks/useServices';
import './LoadingContainer.scss';

interface ILoadingContainerProps {
  isLoading?: boolean;
  loadingMessage?: string;
  children: React.ReactNode;
}

const loadingMessageList = [
  '[A MEME]',
  'Suh dud',
  'PK Load Ω',
  'Generating terrain...',
  'Ready Player One',
  'Did they just walk up slowly and load?',
  'Switching sides...',
  'Dispatching carrier pigeons...',
  'Is this thing on..?',
  'Error 404: Joke Not Found',
  'We at pumpkin hill, you ready?',
  'Preparing Final Form...',
  'ULTIMATE IS READY!',
  'Activating Witch Time...',
  'Insert Coin to Continue',
  'Spinning to win...',
  'Entering cheat codes...',
  'Rushing B...',
  'Pressing random buttons...',
  'Cheat Code Activated.',
  "My cousin's out fighting dragons, and what do I get? Loading tip duty.",
  'Resetting Run...',
  'Removing pen from pineapple...',
  'Caution: Contents Spicy',
  'Good News Everyone!',
  'Resurrecting dead memes...',
  'Clicking circles (to the beat!)',
  'Building Lore...',
  `We don't need a healer for this, right?`,
  'Wubba Lubba Dub Dub',
  'Scaling Bananas',
  'l o a d i n g a e s t h e t i c s',
  'Mozambique here!',
  'Easter egg! Wow!',
  'Does anyone actually read this, huh?',
  'Not gonna wait anymore...',
  'Scooting and looting, amigo',
  'There is never a second opportunity to make a first impression',
  'Świat się zmienia, słońce zachodzi, a wódka się kończy',
  'Where the f*** I am?',
  'Do not eat the yellow snow',
  'Hi there!',
  'W.A.I.T.I.N.G. 2',
  'Postponement 2077',
  'Wife is strange',
  '. . .',
  'Fighting for Azeroth...',
  'Volume to da maximum!',
  'Calculating div sizes...',
  'Applying CSS...',
  'Synchronization 100%',
  'Depressurization!',
  'LMG mounted and loaded!',
  'Cheeki breeki!',
];

export const LoadingContainer: React.FC<ILoadingContainerProps> = observer(
  (props: ILoadingContainerProps) => {
    const { isLoading = false, loadingMessage, children } = props;

    const { turnPreviewModeOn, error } = useServices().globalStore.resolve();

    const [isVisible, setIsVisible] = useState(true);

    const message = useMemo(
      () =>
        loadingMessage ?? loadingMessageList[Math.floor(Math.random() * loadingMessageList.length)],
      [loadingMessage],
    );

    useEffect(() => {
      if (!isLoading) {
        setTimeout(() => {
          setIsVisible(false);
        }, 500);
      } else {
        setIsVisible(true);
      }
    }, [isLoading]);

    const renderError = useMemo(
      () => (
        <div className={'error-container'}>
          <Text variant={'xxLarge'}>Error!</Text>
          <Label styles={{ root: { fontSize: '1rem' } }}>{error}</Label>
        </div>
      ),
      [error],
    );

    const renderLoader = useMemo(
      () => (
        <>
          <Spinner label={message} size={SpinnerSize.large} />
          <Label>{`${isLoading ? 'Connecting' : 'We are ready'}`.toLocaleUpperCase()}</Label>
        </>
      ),
      [isLoading, message],
    );

    const renderLoadingContainer = useMemo(
      () => (
        <div className={`loading-spinner${!isLoading ? ' fade-out' : ''}`}>
          {error ? renderError : renderLoader}
          <ActionButton onClick={turnPreviewModeOn}>Try VolumeMixer in preview mode</ActionButton>
        </div>
      ),
      [isLoading, error, renderError, renderLoader, turnPreviewModeOn],
    );

    return (
      <>
        {!isLoading && children}
        {isVisible && renderLoadingContainer}
      </>
    );
  },
);
