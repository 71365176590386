import { action, makeObservable, observable } from 'mobx';
import { IPotMeasurements } from '../types/IPotMeasurements';

export class PotMeasurements implements IPotMeasurements {
  public potId: number;
  public volume: number;

  public constructor(data: IPotMeasurements) {
    this.potId = data.potId;
    this.volume = data.volume;

    makeObservable(this, {
      volume: observable,
      setVolume: action,
    });
  }

  public setVolume = (value: number): void => {
    this.volume = value;
  };

  public randomVolume = (): void => {
    this.setVolume((Math.random() * 100) ^ 0);
  };
}
