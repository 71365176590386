import * as signalR from '@microsoft/signalr';
import { ConnectionStateEnum } from '../constants/ConnectionStateEnum';
import { LogLevel } from '../constants/LogLevel';
import { ToastTypeEnum } from '../constants/ToastTypeEnum';
import { Toast } from '../models/Toast';
import { ToastWithButton } from '../models/ToastWithButton';
import { IHubConnection } from '../types/IConnection';
import { Logger } from '../utils/Logger';
import { ServiceProvider } from './ServiceProvider';
import { ServiceWithSubscription } from './ServiceWithSubscription';

export class ConnectionService extends ServiceWithSubscription<ConnectionStateEnum> {
  public className = 'ConnectionService';
  private readonly connection: IHubConnection;
  private connectionState: ConnectionStateEnum = ConnectionStateEnum.Closed;
  private _toastId: Toast['id'] | undefined;

  public constructor(serviceProvider: ServiceProvider, url: string) {
    super(serviceProvider);
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect(this.getNextRetryDelay)
      .configureLogging(this._logger)
      .build();
  }

  public readonly withConnection = <T>(callback: (connection: IHubConnection) => T): T => {
    if (this.connectionState !== ConnectionStateEnum.Open) {
      throw new Error('Connection failed. Cannot process the request');
    }

    return callback(this.connection);
  };

  public readonly connect = (reconnectCount = 0): void => {
    if (this.connectionState === ConnectionStateEnum.Open) {
      return;
    }
    this.connectionState = ConnectionStateEnum.Connecting;
    this.onConnectionStateChanged();
    if (this.serviceProvider.globalStore.resolve().isTesting) {
      setTimeout(() => {
        this.connectionState = ConnectionStateEnum.Open;
        this.onConnectionStateChanged();
      }, 1000);
      return;
    }
    this.connection
      .start()
      .then(() => {
        if (this.connectionState === ConnectionStateEnum.Open) {
          return;
        }
        this.connectionState = ConnectionStateEnum.Open;
        this.onConnectionStateChanged();
        this.serviceProvider.receiveService.resolve();
        void this.serviceProvider.sendService.resolve().handShake();
      })
      .catch((error: Error) => {
        if (this.connectionState === ConnectionStateEnum.Open) {
          return;
        }
        const reconnectInterval = this.getNextRetryDelay.nextRetryDelayInMilliseconds({
          previousRetryCount: reconnectCount,
          elapsedMilliseconds: 0,
          retryReason: error,
        });

        this.connectionState = ConnectionStateEnum.Broken;
        this.onConnectionStateChanged();

        if (reconnectInterval === null) {
          this.hideToast();
          this._toastId = this.serviceProvider.globalStore.resolve().addToast(
            new ToastWithButton({
              message: 'Connection is not established. Reconnect manually?',
              isControlsHidden: true,
              type: ToastTypeEnum.Warning,
              timeAlive: null,
              onButtonClick: (toast) => {
                const globalStore = this.serviceProvider.globalStore.resolve();
                globalStore.hideToast(toast.id);
                globalStore.setError(undefined);
                this.connect();
              },
              buttonText: 'Reconnect',
            }),
          );
          Logger.log({
            logLevel: LogLevel.Critical,
            callerName: this.className,
            method: 'Connection',
            message:
              'Connection is not established. Reached maximum reconnect attempts. Reconnect manually or refresh page.',
            meta: error,
          });
          return;
        }

        Logger.log({
          logLevel: LogLevel.Warning,
          callerName: this.className,
          method: 'Connection',
          message: `Connection is not established. Trying to connect in ${
            reconnectInterval / 1000
          } second${reconnectInterval / 1000 === 1 ? '' : 's'}...`,
          meta: error,
        });

        setTimeout(() => {
          this.connect(reconnectCount + 1);
        }, reconnectInterval);
      });

    this.connection.onreconnecting((error) => {
      this.connectionState = ConnectionStateEnum.Reconnecting;
      this.hideToast();
      this._toastId = this.serviceProvider.globalStore.resolve().addToast(
        new Toast({
          message: 'Connection lost. Trying to reconnect...',
          isControlsHidden: true,
          type: ToastTypeEnum.Warning,
          timeAlive: null,
        }),
      );
      Logger.log({
        logLevel: LogLevel.Error,
        callerName: this.className,
        method: 'Reconnecting',
        message: 'Connection lost due to error.',
        meta: error,
      });
      this.onConnectionStateChanged();
    });

    this.connection.onreconnected(() => {
      this.connectionState = ConnectionStateEnum.Open;
      this.onConnectionStateChanged();
      this.hideToast();
      void this.serviceProvider.sendService.resolve().handShake();
    });

    this.connection.onclose(() => {
      this.connectionState = ConnectionStateEnum.Closed;
      this.onConnectionStateChanged();
      /*
       TODO: Make connection "Closed"
        when reconnect isn't possible multiple times.
         Ask to refresh page.
      */
      this.connect();
    });
  };

  private readonly onConnectionStateChanged = () => {
    Logger.log({
      logLevel: LogLevel.Information,
      callerName: this.className,
      method: 'Connection',
      message: `is now "${ConnectionStateEnum[this.connectionState]}"`,
    });
    this.broadcast(this.connectionState);
  };

  private readonly hideToast = (): void => {
    if (this._toastId) this.serviceProvider.globalStore.resolve().hideToast(this._toastId);
  };

  //region SignalR Utils
  private readonly getNextRetryDelay: signalR.IRetryPolicy = {
    nextRetryDelayInMilliseconds: ({ previousRetryCount }) =>
      previousRetryCount <= 5
        ? 1000
        : previousRetryCount <= 8
        ? 5000
        : previousRetryCount <= 18
        ? 10000
        : previousRetryCount <= 22
        ? 30000
        : previousRetryCount <= 23
        ? 60000
        : null,
  };
  private readonly _logger: signalR.ILogger = {
    log: (logLevel: LogLevel, message: string) =>
      Logger.log({
        logLevel: logLevel,
        callerName: this.className,
        method: 'SignalR',
        message: message,
      }),
  };
  //endregion
}
