import { ColorPicker, IColor, Label } from '@fluentui/react';
import React, { SyntheticEvent } from 'react';
import './ColorPickerWithLabel.scss';

interface IColorPickerWithLabelProps {
  label?: string;
  color: string;

  onChange: (color: IColor) => void;
}

export const ColorPickerWithLabel: React.FC<IColorPickerWithLabelProps> = (
  props: IColorPickerWithLabelProps,
) => {
  const { label, color, onChange } = props;

  const onColorChange = (_: SyntheticEvent<HTMLElement>, color: IColor) => {
    onChange(color);
  };

  return (
    <div className={'color-picker'}>
      <Label>{label}</Label>
      <ColorPicker alphaType={'none'} showPreview={true} color={color} onChange={onColorChange} />
    </div>
  );
};
