import { action, makeObservable, observable } from 'mobx';
import { IProfile } from '../types/IProfile';
import { IVolumePot } from '../types/IVolumePot';
import { ObjectHash } from '../utils/ObjectHash';
import { VolumePot } from './VolumePot';
import { VolumePotMaster } from './VolumePotMaster';

export class Profile implements IProfile {
  public initialHash: string;
  public get hash(): string {
    return ObjectHash.md5({ ...this, initialHash: undefined });
  }

  public id: string;
  public name: string;
  public volumePotList: VolumePot[];
  public masterPot: VolumePotMaster = new VolumePotMaster();
  public pushToTalk: boolean;

  public constructor(props: IProfile) {
    makeObservable(this, {
      volumePotList: observable,
      pushToTalk: observable,
      name: observable,
      togglePushToTalk: action,
      setName: action,
    });

    const { id, name, volumePotList, pushToTalk = false } = props;
    this.id = id;
    this.name = name;
    this.volumePotList = volumePotList.map((pot: IVolumePot) => new VolumePot(pot));
    this.pushToTalk = pushToTalk;

    this.initialHash = this.hash;
  }

  public togglePushToTalk = (pushToTalk?: boolean): void => {
    pushToTalk = pushToTalk ?? !this.pushToTalk;
    this.pushToTalk = pushToTalk;
  };

  public setName = (value?: string): void => {
    this.name = value ?? '';
  };
}
