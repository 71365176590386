import { LogLevel } from '../constants/LogLevel';
import { ILoggerConsole, ILoggerConsoleFunction, ILoggerProperties } from '../types/ILogger';

export class Logger {
  protected static readonly _minimumLogLevel: LogLevel = process.env.REACT_APP_LOG_LEVEL
    ? +process.env.REACT_APP_LOG_LEVEL
    : LogLevel.Warning;

  protected static readonly outputConsole: ILoggerConsole = console;

  public static readonly log = (data: ILoggerProperties): void => {
    const { logLevel } = data;

    switch (logLevel) {
      case LogLevel.Critical:
        Logger.sendLog(Logger.outputConsole.error, data);
        break;
      case LogLevel.Error:
        Logger.sendLog(Logger.outputConsole.error, data);
        break;
      case LogLevel.Warning:
        Logger.sendLog(Logger.outputConsole.warn, data);
        break;
      case LogLevel.Information:
        Logger.sendLog(Logger.outputConsole.info, data);
        break;
      case LogLevel.Debug:
        Logger.sendLog(Logger.outputConsole.debug, data);
        break;
      case LogLevel.Trace:
        Logger.sendLog(Logger.outputConsole.trace, data);
        break;
      default:
        Logger.sendLog(Logger.outputConsole.log, data);
    }
  };

  protected static readonly sendLog = (
    consoleMethod: ILoggerConsoleFunction,
    data: ILoggerProperties,
  ): void => {
    const { logLevel, callerName, method, message, meta } = data;
    // TODO: Send all logs to backend

    if (logLevel >= Logger._minimumLogLevel) {
      consoleMethod(
        '%c[%s] %c%s: %c[%s]\n    %c%s: %c%s',
        'color:slateGray',
        new Date().toLocaleTimeString(),
        'color:lightGreen',
        LogLevel[logLevel].toUpperCase(),
        'color:Turquoise',
        callerName,
        'color:orange',
        `${method}`,
        'color:snow',
        `${message}`,
        ...(meta ? [meta] : []),
      );
    }
  };
}
