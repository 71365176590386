import { IconButton, TextField } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Profile } from '../../../../models/Profile';
import { BreadcrumbTitle } from '../../../shared/LayoutContainer/BreadcrumbTitle/BreadcrumbTitle';
import './BreadcrumbProfileTitle.scss';

interface IBreadcrumbProfileTitleProps {
  profile: Profile;
}

export const BreadcrumbProfileTitle: React.FC<IBreadcrumbProfileTitleProps> = observer(
  (props: IBreadcrumbProfileTitleProps) => {
    const { profile } = props;

    const [isEditing, setEditing] = useState<boolean>(false);
    const [newName, setNewName] = useState<string>('');

    const onEditClick = () => {
      if (isEditing) profile.setName(newName);
      else setNewName(profile.name ?? 'Profile');
      setEditing(!isEditing);
    };
    const onNameChange = (event: React.FormEvent, value: string | undefined) =>
      setNewName(value ?? '');

    return (
      <BreadcrumbTitle parent={{ title: 'Profiles', url: 'profiles' }}>
        {isEditing ? (
          <TextField value={profile.name} onChange={onNameChange} />
        ) : (
          <span>{profile.name}</span>
        )}
        <IconButton
          iconProps={{ iconName: isEditing ? 'Save' : 'Edit' }}
          className={'breadcrumb-edit-icon'}
          onClick={onEditClick}
        />
      </BreadcrumbTitle>
    );
  },
);
