import { Toggle } from '@fluentui/react';
import React from 'react';
import { useThrottle } from '../../../hooks/useThrottle';
import './PushToTalk.scss';

interface IPushToTalkProps {
  isToggle: boolean;

  onChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean | undefined,
  ) => void;
}

export const PushToTalk: React.FC<IPushToTalkProps> = (props: IPushToTalkProps) => {
  const { isToggle, onChange } = props;

  const onToggle = useThrottle(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => {
      event.preventDefault();
      event.stopPropagation();
      onChange(event, checked ?? !isToggle);
    },
    100,
  );

  return (
    <div className={'push-to-talk-component'} onClick={onToggle}>
      <Toggle
        label="Push to talk"
        onText="On"
        offText="Off"
        checked={isToggle}
        onChange={onToggle}
      />
    </div>
  );
};
