import { action, makeObservable, observable } from 'mobx';
import { IMeasurements } from '../types/IMeasurements';
import { IPotMeasurements } from '../types/IPotMeasurements';
import { PotMeasurements } from './PotMeasurements';

export class Measurements implements IMeasurements {
  public potMeasurements: PotMeasurements[];

  public micMuteState: boolean;
  public volumeMuteState: boolean;

  public darkMode: boolean;

  public constructor(data: IMeasurements) {
    this.potMeasurements = data.potMeasurements.map(
      (measurement) => new PotMeasurements(measurement),
    );

    makeObservable(this, {
      potMeasurements: observable,
      micMuteState: observable,
      volumeMuteState: observable,
      darkMode: observable,
      addPotMeasurements: action,
      setMicMuteState: action,
      setVolumeMuteState: action,
      setDarkMode: action,
    });

    this.micMuteState = data.micMuteState ?? false;
    this.volumeMuteState = data.volumeMuteState ?? false;
    this.darkMode = data.darkMode ?? false;
  }

  public getMeasurementsByPotId = (id: number): PotMeasurements | undefined =>
    this.potMeasurements.find((measurement) => measurement.potId === id);

  public addPotMeasurements = (pot: IPotMeasurements): void => {
    this.potMeasurements.push(new PotMeasurements(pot));
  };

  public setMicMuteState = (state = false): void => {
    this.micMuteState = state;
  };
  public setVolumeMuteState = (state = false): void => {
    this.volumeMuteState = state;
  };
  public setDarkMode = (state = false): void => {
    this.darkMode = state;
  };

  public static get default(): Measurements {
    return new Measurements({
      potMeasurements: [],
    });
  }
}
