import { action, makeObservable, observable } from 'mobx';
import { DeviceInfo } from '../models/DeviceInfo';
import { ServiceProvider } from '../services/ServiceProvider';
import { IDeviceInfo } from '../types/IDeviceInfo';
import { StoreBase } from './StoreBase';

export class SideStore extends StoreBase {
  public className = 'SideStore';
  //region Properties

  public deviceInfo: DeviceInfo;

  //endregion

  public constructor(serviceProvider: ServiceProvider) {
    super(serviceProvider);

    makeObservable(this, {
      deviceInfo: observable,
      updateDeviceInfo: action,
    });

    this.deviceInfo = new DeviceInfo(this.serviceProvider.dataProvider.resolve().getDeviceInfo());
  }

  //region Methods

  public updateDeviceInfo = (deviceInfo: IDeviceInfo): void => {
    this.deviceInfo = new DeviceInfo(deviceInfo);
  };

  //endregion
}
