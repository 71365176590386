import { makeObservable, observable } from 'mobx';
import { AppUpdatePeriodEnum, LanguageEnum } from '../constants/Settings';
import { ISettings } from '../types/ISettings';
import { EditableModelBase } from './EditableModelBase';
import { Overlay } from './Overlay';

export class Settings extends EditableModelBase implements ISettings {
  public isLaunchOnStartup: boolean;
  public isHideInTray: boolean;
  public isStartCollapsed: boolean;
  public language: LanguageEnum;
  public isStoreConfigsPortable: boolean;

  public appUpdatePeriod: AppUpdatePeriodEnum;
  public isSubscribeToBetaUpdates: boolean;

  public isSyncThemeWithOs: boolean;
  public isDarkTheme: boolean;

  public overlay: Overlay;

  public constructor(props: ISettings) {
    super();

    makeObservable(this, {
      isLaunchOnStartup: observable,
      isHideInTray: observable,
      isStartCollapsed: observable,
      language: observable,
      isStoreConfigsPortable: observable,
      appUpdatePeriod: observable,
      isSubscribeToBetaUpdates: observable,
      isSyncThemeWithOs: observable,
      isDarkTheme: observable,
      overlay: observable,
    });

    this.isLaunchOnStartup = props.isLaunchOnStartup;
    this.isHideInTray = props.isHideInTray;
    this.isStartCollapsed = props.isStartCollapsed;
    this.language = props.language;
    this.isStoreConfigsPortable = props.isStoreConfigsPortable;

    this.appUpdatePeriod = props.appUpdatePeriod;
    this.isSubscribeToBetaUpdates = props.isSubscribeToBetaUpdates;

    this.isSyncThemeWithOs = props.isSyncThemeWithOs;
    this.isDarkTheme = props.isDarkTheme;

    this.overlay = new Overlay(props.overlay);
  }

  public static get default(): ISettings {
    return {
      isLaunchOnStartup: true,
      isHideInTray: true,
      isStartCollapsed: true,
      language: LanguageEnum.English,
      isStoreConfigsPortable: false,
      appUpdatePeriod: AppUpdatePeriodEnum.Daily,
      isSubscribeToBetaUpdates: false,
      isSyncThemeWithOs: false,
      isDarkTheme: true,
      overlay: {
        overlayPosition: { corner: 0, xPosition: 0, yPosition: 0 },
        isVisible: true,
        overlayMonitor: 0,
        opacity: 75,
        scale: 50,
      },
    };
  }

  public toApi = (): ISettings => {
    return {
      isLaunchOnStartup: this.isLaunchOnStartup,
      isHideInTray: this.isHideInTray,
      isStartCollapsed: this.isStartCollapsed,
      language: this.language,
      isStoreConfigsPortable: this.isStoreConfigsPortable,

      appUpdatePeriod: this.appUpdatePeriod,
      isSubscribeToBetaUpdates: this.isSubscribeToBetaUpdates,

      isDarkTheme: this.isDarkTheme,
      isSyncThemeWithOs: this.isSyncThemeWithOs,

      overlay: this.overlay.toApi(),
    };
  };
}
