import { ConnectionStateEnum } from '../constants/ConnectionStateEnum';
import { CustomizationColorModeEnum } from '../constants/Customization';
import { HWButtonEnum } from '../constants/HWButtonEnum';
import { LogLevel } from '../constants/LogLevel';
import { Color } from '../models/Color';
import { Customization } from '../models/Customization';
import { DeviceInfo } from '../models/DeviceInfo';
import { LinearGradient } from '../models/LinearGradient';
import { Measurements } from '../models/Measurements';
import { Settings } from '../models/Settings';
import { VolumePot } from '../models/VolumePot';
import { IPotUpdatePayload } from '../types/Api/IPotUpdatePayload';
import { IApplication } from '../types/IApplication';
import { IDeviceInfo } from '../types/IDeviceInfo';
import { IMeasurements } from '../types/IMeasurements';
import { IPotMeasurements } from '../types/IPotMeasurements';
import { IProfile } from '../types/IProfile';
import { ISettings } from '../types/ISettings';
import { Logger } from '../utils/Logger';
import { ServiceBase } from './ServiceBase';
import { ServiceProvider } from './ServiceProvider';

export class DataProviderService extends ServiceBase {
  public className = 'DataProviderService';
  private _profileList: IProfile[] = [];
  private _selectedProfile: IProfile['id'] | undefined;
  private _settings: ISettings = Settings.default;
  private _applicationList: IApplication[] = [];
  private _deviceInfo: IDeviceInfo = DeviceInfo.default;
  private _measurements: IMeasurements = Measurements.default;

  public constructor(serviceProvider: ServiceProvider) {
    super(serviceProvider);

    this.serviceProvider.connectionService.resolve().subscribe((connectionState) => {
      if (
        connectionState === ConnectionStateEnum.Closed ||
        connectionState === ConnectionStateEnum.Broken
      ) {
        this.resetStores();
      }
    });
  }

  //region Test dataset

  private static get testProfileList(): IProfile[] {
    return [
      {
        id: 'first',
        name: 'Default profile',
        volumePotList: [
          new VolumePot({
            id: 1,
            name: 'Browsing',
            appList: Array.from({ length: 4 }, (_, index) => ({
              processName: DataProviderService.generateTestProcessName(index),
              processTime: new Date(),
            })),
            customization: {
              mode: CustomizationColorModeEnum.Gradient,
              color: new Color(0, 0, 0),
              gradient: new LinearGradient(new Color(255, 95, 243), new Color(242, 91, 91)),
            },
          }),
          new VolumePot({
            id: 2,
            name: 'Music',
            appList: Array.from({ length: 3 }, (_, index) => ({
              processName: DataProviderService.generateTestProcessName(index + 3),
              processTime: new Date(),
            })),
            customization: {
              mode: CustomizationColorModeEnum.Gradient,
              color: new Color(0, 0, 0),
              gradient: new LinearGradient(new Color(248, 160, 90), new Color(242, 91, 91)),
            },
          }),
          new VolumePot({
            id: 3,
            name: 'Games',
            appList: Array.from({ length: 37 }, (_, index) => ({
              processName: DataProviderService.generateTestProcessName(index + 7),
              processTime: new Date(),
            })),
            customization: {
              mode: CustomizationColorModeEnum.Gradient,
              color: new Color(0, 0, 0),
              gradient: new LinearGradient(new Color(255, 95, 243), new Color(252, 91, 91)),
            },
          }),
          new VolumePot({
            id: 4,
            name: 'VoIP',
            appList: Array.from({ length: 7 }, (_, index) => ({
              processName: DataProviderService.generateTestProcessName(index),
              processTime: new Date(),
            })),
            customization: {
              mode: CustomizationColorModeEnum.Gradient,
              color: new Color(0, 0, 0),
              gradient: new LinearGradient(new Color(255, 95, 243), new Color(56, 119, 255)),
            },
          }),
        ],
        masterPot: {
          id: 0,
          name: 'Master',
        },
      },
      {
        id: 'second',
        name: 'Second test profile',
        volumePotList: [
          new VolumePot({
            id: 1,
            name: 'Test',
            appList: [],
            customization: new Customization(),
          }),
          new VolumePot({
            id: 2,
            name: 'Test',
            appList: [],
            customization: new Customization(),
          }),
          new VolumePot({
            id: 3,
            name: 'Test',
            appList: [],
            customization: new Customization(),
          }),
          new VolumePot({
            id: 4,
            name: 'Test',
            appList: Array.from({ length: 17 }, (_, index) => ({
              processName: DataProviderService.generateTestProcessName(index + 5),
              processTime: new Date(),
            })),
            customization: new Customization(),
          }),
        ],
        masterPot: {
          id: 0,
          name: 'Master',
        },
      },
    ];
  }

  private static get testApplicationList(): IApplication[] {
    return Array.from({ length: 100 }, (_, index) => ({
      processName: DataProviderService.generateTestProcessName(index),
      processTime: new Date(),
    }));
  }

  private static get testDeviceInfo(): IDeviceInfo {
    return {
      model: { modelName: 'Test', potCount: 4, isRgbAvailable: false, unitCode: 0 },
      deviceId: '3A39006C-D501-48DD-8688-367BC1D9351F',
      firmwareVersion: '2.07.002',
      latestUpdate: new Date(),
      connectionInfo: { type: 1, address: 'Some USB?' },
    };
  }

  private static get testMeasurements(): IMeasurements {
    return {
      potMeasurements: [
        { potId: 0, volume: 70 },
        { potId: 1, volume: 100 },
        { potId: 2, volume: 30 },
        { potId: 3, volume: 0 },
        { potId: 4, volume: 50 },
      ],
      volumeMuteState: false,
      micMuteState: true,

      darkMode: false,
    };
  }

  private static get testSettings(): ISettings {
    return Settings.default;
  }

  private static readonly generateTestProcessName = (id: number): string => {
    return `Process ${id}.exe`;
  };

  //endregion

  //region Get methods

  public getProfileList(): IProfile[] {
    if (this.serviceProvider.globalStore.resolve().isTesting) {
      return DataProviderService.testProfileList;
    }
    return this._profileList;
  }

  public getSelectedProfile(): IProfile['id'] | undefined {
    return this._selectedProfile;
  }

  public getApplicationList(): IApplication[] {
    if (this.serviceProvider.globalStore.resolve().isTesting) {
      return DataProviderService.testApplicationList;
    }
    return this._applicationList;
  }

  public getDeviceInfo(): IDeviceInfo {
    if (this.serviceProvider.globalStore.resolve().isTesting) {
      return DataProviderService.testDeviceInfo;
    }
    return this._deviceInfo;
  }

  public getMeasurements(): IMeasurements {
    if (this.serviceProvider.globalStore.resolve().isTesting) {
      return DataProviderService.testMeasurements;
    }
    return this._measurements;
  }

  public getSettings(): ISettings {
    if (this.serviceProvider.globalStore.resolve().isTesting) {
      return DataProviderService.testSettings;
    }
    return this._settings;
  }

  //endregion

  //region Update methods

  public updateProfileList = (profileList: IProfile[]): void => {
    if (this.serviceProvider.dataStore.isResolved) {
      this.serviceProvider.dataStore.resolve().updateProfileList(profileList);
    } else {
      this._profileList = profileList;
    }
  };

  public addProfile = (profileList: IProfile): void => {
    if (this.serviceProvider.dataStore.isResolved) {
      this.serviceProvider.dataStore.resolve().addProfile(profileList);
    } else {
      this._profileList.push(profileList);
    }
  };

  public updateSelectedProfile = (profileId?: string): void => {
    if (!profileId) return;
    if (this.serviceProvider.dataStore.isResolved) {
      this.serviceProvider.dataStore.resolve().onProfileChange(profileId, false);
    } else {
      this._selectedProfile = profileId;
    }
  };

  public updateApplicationList = (applicationList: IApplication[]): void => {
    if (this.serviceProvider.applicationListStore.isResolved) {
      this.serviceProvider.applicationListStore.resolve().updateApplicationList(applicationList);
    } else {
      this._applicationList = applicationList;
    }
  };

  public updateDeviceInfo = (deviceInfo: IDeviceInfo): void => {
    if (this.serviceProvider.sideStore.isResolved) {
      this.serviceProvider.sideStore.resolve().updateDeviceInfo(deviceInfo);
    } else {
      this._deviceInfo = deviceInfo;
    }
  };

  public updateMeasurements = (measurements: IMeasurements): void => {
    if (this.serviceProvider.measurementStore.isResolved) {
      this.serviceProvider.measurementStore.resolve().updateMeasurements(measurements);
    } else {
      this._measurements = measurements;
    }
  };

  public updatePotVolume = (potMeasurements: IPotMeasurements): void => {
    if (this.serviceProvider.measurementStore.isResolved) {
      const measurements = this.serviceProvider.measurementStore.resolve().measurements;
      const pot = measurements.getMeasurementsByPotId(potMeasurements.potId);
      if (pot) {
        pot.setVolume(potMeasurements.volume);
      } else {
        measurements.addPotMeasurements(potMeasurements);
      }
    } else if (this._measurements) {
      const potIdx = this._measurements.potMeasurements.findIndex(
        (measurement) => measurement.potId === potMeasurements.potId,
      );
      if (~potIdx) {
        this._measurements.potMeasurements[potIdx] = potMeasurements;
      } else {
        this._measurements.potMeasurements = [
          ...this._measurements.potMeasurements,
          potMeasurements,
        ];
      }
    } else {
      this.updateMeasurements({ potMeasurements: [potMeasurements] });
    }
  };

  public updateButton = (payload: IPotUpdatePayload): void => {
    switch (payload.eventSourceCode) {
      case HWButtonEnum.MicMute:
        this.updateMicMuteState(!!payload.value);
        break;
      case HWButtonEnum.MasterMute:
        this.updateVolumeMuteState(!!payload.value);
        break;
      case HWButtonEnum.DarkMode:
        this.updateDarkMode(!!payload.value);
        break;
      default:
        Logger.log({
          logLevel: LogLevel.Error,
          callerName: this.className,
          method: 'Unknown button',
          message: payload.eventSourceCode.toString(10),
          meta: payload,
        });
    }
  };

  public updateMicMuteState = (state = false): void => {
    if (this.serviceProvider.measurementStore.isResolved) {
      this.serviceProvider.measurementStore.resolve().measurements.setMicMuteState(state);
    } else if (this._measurements) {
      this._measurements.micMuteState = state;
    } else {
      this.updateMeasurements({ potMeasurements: [], micMuteState: state });
    }
  };

  public updateVolumeMuteState = (state = false): void => {
    if (this.serviceProvider.measurementStore.isResolved) {
      this.serviceProvider.measurementStore.resolve().measurements.setVolumeMuteState(state);
    } else if (this._measurements) {
      this._measurements.volumeMuteState = state;
    } else {
      this.updateMeasurements({ potMeasurements: [], volumeMuteState: state });
    }
  };

  public updateDarkMode = (state = false): void => {
    if (this.serviceProvider.measurementStore.isResolved) {
      this.serviceProvider.measurementStore.resolve().measurements.setDarkMode(state);
    } else if (this._measurements) {
      this._measurements.darkMode = state;
    } else {
      this.updateMeasurements({ potMeasurements: [], darkMode: state });
    }
  };

  public updateSettings = (settings: ISettings): void => {
    if (this.serviceProvider.settingsStore.isResolved) {
      this.serviceProvider.settingsStore.resolve().updateSettings(settings);
    } else {
      this._settings = settings;
    }
  };

  //endregion

  //region Internal methods

  public togglePushToTalk = (pushToTalk?: boolean): void => {
    const { currentProfile } = this.serviceProvider.dataStore.resolve();
    currentProfile?.togglePushToTalk(pushToTalk);
  };

  //endregion

  public resetStores = (): void => {
    this.updateProfileList([]);
    this.updateSelectedProfile('');
    this.updateSettings(Settings.default);
    this.updateApplicationList([]);
    this.updateDeviceInfo(DeviceInfo.default);
    this.updateMeasurements(Measurements.default);
  };
}
