import { Checkbox } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useServices } from '../../../hooks/useServices';
import './Appearance.scss';

export const Appearance: React.FC = observer(() => {
  const { settings } = useServices().settingsStore.resolve();
  const { isSyncThemeWithOs, setProperty: set } = settings;

  return (
    <div>
      <Checkbox
        checked={isSyncThemeWithOs}
        className={'checkbox-with-label'}
        label={'Sync with OS Settings'}
        onRenderLabel={(...props) => (
          <div className={'label-wrapper'}>
            <span>{props[0]?.label || ''}</span>
            <p>Automatically switch between light and dark theme when your system does</p>
          </div>
        )}
        onChange={(e, value) => set('isSyncThemeWithOs', !!value)}
      />
    </div>
  );
});
