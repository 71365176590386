import { ScrollablePane, ScrollbarVisibility, Stack } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useServices } from '../../../hooks/useServices';
import { EmptyCard } from './ProfileCard/EmptyCard/EmptyCard';
import { ProfileCard } from './ProfileCard/ProfileCard';
import './ProfileList.scss';

export const ProfileList: React.FC = observer(() => {
  const { profileList, currentProfile, createProfile } = useServices().dataStore.resolve();

  return (
    <div className={'profile-list__'} style={{ position: 'relative', flex: 1 }}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <Stack
          horizontal
          wrap
          tokens={{ childrenGap: 15 }}
          styles={{
            root: { overflow: 'hidden' },
          }}
        >
          <EmptyCard onCreateProfileClick={createProfile} />
          {profileList.map((profile) => (
            <ProfileCard
              key={profile.id}
              profile={profile}
              isSelected={profile.id === currentProfile?.id}
            />
          ))}
        </Stack>
      </ScrollablePane>
    </div>
  );
});
