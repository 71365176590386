import _ from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { LogLevel } from '../constants/LogLevel';
import { ToastTypeEnum } from '../constants/ToastTypeEnum';
import { ConstructorException } from '../exceptions/ConstructorException';
import { Profile } from '../models/Profile';
import { Toast } from '../models/Toast';
import { VolumePot } from '../models/VolumePot';
import { ServiceProvider } from '../services/ServiceProvider';
import { IProfile } from '../types/IProfile';
import { Logger } from '../utils/Logger';
import { StoreWithSave } from './StoreWithSave';

export class DataStore extends StoreWithSave {
  public className = 'DataStore';
  //region Properties

  public profileList: Profile[];
  public selectedPotId: number | undefined;

  private _currentProfile: { saved: boolean; id: string };
  private _changedProfiles: string[] = [];

  //endregion

  public constructor(serviceProvider: ServiceProvider) {
    super(serviceProvider);

    makeObservable<DataStore, '_currentProfile'>(this, {
      profileList: observable,
      selectedPotId: observable,
      _currentProfile: observable,
      currentProfile: computed,
      selectedPot: computed,
      updateProfileList: action,
      onProfileChange: action,
      selectPot: action,
    });

    try {
      this.profileList = this.serviceProvider.dataProvider
        .resolve()
        .getProfileList()
        .map((profile) => new Profile(profile));
    } catch (error) {
      if (error instanceof ConstructorException) {
        Logger.log({
          logLevel: LogLevel.Critical,
          callerName: this.className,
          method: error.constructor.name,
          message: error.message,
          meta: error.failedObject,
        });
      } else {
        Logger.log({
          logLevel: LogLevel.Critical,
          callerName: this.className,
          method: 'Unhandled exception',
          message: error.message,
          meta: error,
        });
      }
      this.profileList = [];
    }

    this._currentProfile = {
      saved: true,
      id:
        this.serviceProvider.dataProvider.resolve().getSelectedProfile() ??
        this.profileList.find(() => true)?.id ??
        '',
    };

    this.selectedPotId = this.profileList.find(() => true)?.volumePotList.find(() => true)?.id;
  }

  public get currentProfile(): Profile | undefined {
    return this.profileList.find((profile) => profile.id === this._currentProfile.id) ?? undefined;
  }

  public get selectedPot(): VolumePot | undefined {
    if (this.selectedPotId === undefined) return undefined;
    return (
      this.currentProfile?.volumePotList.find((pot) => pot.id === this.selectedPotId) ?? undefined
    );
  }

  //region Methods

  public updateProfileList = (profileList: IProfile[]): void => {
    this.profileList = profileList.map((profile) => observable(new Profile(profile)));
    this._currentProfile = {
      saved: true,
      id: this.profileList.find(() => true)?.id ?? '',
    };
    this.selectedPotId = this.profileList.find(() => true)?.volumePotList.find(() => true)?.id;
  };

  public addProfile = (profile: IProfile): void => {
    this.profileList.push(new Profile(profile));
  };

  public onProfileChange = (profileId: string, save = true): void => {
    if (this._currentProfile.id === profileId) return;

    this._currentProfile = { saved: !save, id: profileId };
    this.serviceProvider.globalStore.resolve().addToast(
      new Toast({
        message: `Current profile: "${this.currentProfile?.name}"`,
        type: ToastTypeEnum.Info,
        timeAlive: 2000,
      }),
    );
  };

  public createProfile = (): void =>
    void this.serviceProvider.sendService.resolve().createNewProfile();

  public removeProfile = (id: IProfile['id']): void =>
    void this.serviceProvider.sendService.resolve().removeProfile(id);

  public selectPot = (volumePotId: number | undefined): void => {
    this.selectedPotId = volumePotId ?? this.currentProfile?.volumePotList.find(() => true)?.id;
  };

  public saveProfiles = _.debounce((): void => {
    if (!this._changedProfiles.length) return;
    Logger.log({
      logLevel: LogLevel.Debug,
      callerName: this.className,
      method: 'saveProfiles',
      message: `Saving profiles. Changed profiles:\n${this._changedProfiles.join(';\n')}`,
    });

    this._changedProfiles.forEach((profileId) => {
      const profile = this.profileList.find((p) => p.id === profileId);
      if (!profile) {
        Logger.log({
          logLevel: LogLevel.Error,
          callerName: this.className,
          method: 'saveProfiles',
          message: `Profile not found. Id: "${profileId ?? 'undefined'}"`,
        });
        return;
      }

      this.serviceProvider.sendService
        .resolve()
        .saveProfile(profile)
        .then(() => {
          profile.initialHash = profile.hash;
        });
      this._changedProfiles = this._changedProfiles.filter((pId) => pId !== profileId);
    });
  }, 500);

  public save = (): void => {
    this.profileList.forEach((profile) => {
      if (!this._changedProfiles.includes(profile.id) && profile.initialHash !== profile.hash) {
        this._changedProfiles.push(profile.id);
      }
    });
    this.saveProfiles();

    if (!this._currentProfile.saved)
      void this.serviceProvider.sendService.resolve().saveSelectedProfile(this._currentProfile.id);
  };

  //endregion
}
