import { DeviceConnectionTypeEnum } from '../constants/DeviceInfo';
import { IDeviceConnection } from '../types/IDeviceConnection';

export class DeviceConnection implements IDeviceConnection {
  public type: DeviceConnectionTypeEnum;
  public address: string;

  public constructor(deviceConnection: IDeviceConnection) {
    this.type = deviceConnection.type;
    this.address = deviceConnection.address;
  }
}
