import _ from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import { LogLevel } from '../constants/LogLevel';
import { Settings } from '../models/Settings';
import { ServiceProvider } from '../services/ServiceProvider';
import { ISettings } from '../types/ISettings';
import { Logger } from '../utils/Logger';
import { StoreWithSave } from './StoreWithSave';

export class SettingsStore extends StoreWithSave {
  public className = 'SettingsStore';
  public settings: Settings;

  public constructor(serviceProvider: ServiceProvider) {
    super(serviceProvider);

    makeObservable(this, {
      settings: observable,
      updateSettings: action,
    });

    this.settings = new Settings(this.serviceProvider.dataProvider.resolve().getSettings());
  }

  public updateSettings = (settings: ISettings): void => {
    this.settings = new Settings(settings);
  };

  public save = _.debounce(async () => {
    Logger.log({
      logLevel: LogLevel.Debug,
      callerName: this.className,
      method: 'saveSettings',
      message: 'Saving settings',
    });
    await this.serviceProvider.sendService.resolve().saveSettings(this.settings.toApi());
  }, 500);
}
