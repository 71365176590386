import { IToastWithButton } from '../types/Toast';
import { Toast } from './Toast';

export class ToastWithButton extends Toast implements IToastWithButton {
  public buttonText: string;
  public onButtonClick: () => void;

  public constructor(toast: IToastWithButton) {
    super(toast);

    this.buttonText = toast.buttonText;
    this.onButtonClick = () => toast.onButtonClick(this);
  }
}
