import { Icon } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IMeasurements } from '../../../types/IMeasurements';

interface IMasterLabelProps {
  measurements: IMeasurements;
}

export const MasterLabel: React.FC<IMasterLabelProps> = observer((props: IMasterLabelProps) => {
  const { volumeMuteState, micMuteState } = props.measurements;
  return (
    <div className={'master-label'}>
      <Icon iconName={volumeMuteState ? 'VolumeDisabled' : 'Volume2'} />
      <Icon iconName={micMuteState ? 'MicOff' : 'Microphone'} />
    </div>
  );
});
