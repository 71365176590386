import { observer } from 'mobx-react-lite';
import { PivotItem } from '@fluentui/react';
import React from 'react';
import {
  CustomizationColorModeEnum,
  customizationColorModeList,
} from '../../constants/Customization';
import { useServices } from '../../hooks/useServices';
import { VolumePot } from '../../models/VolumePot';
import { ColorPickerWithLabel } from '../shared/ColorPickerWithLabel/ColorPickerWithLabel';
import { ComboBoxComponent } from '../shared/ComboBox/ComboBoxComponent';
import { InfoBox } from '../shared/InfoBox/InfoBox';
import { ProfilePotPivot } from '../shared/Pivot/ProfilePotPivot/ProfilePotPivot';
import './Customization.scss';

export const CustomizationView: React.FC = observer(() => {
  const {
    currentProfile,
    selectedPotId,
    selectedPot,
    selectPot,
  } = useServices().dataStore.resolve();
  const { deviceInfo } = useServices().sideStore.resolve();

  const onPotChange = (item: PivotItem | undefined) => {
    selectPot(+(item?.props?.itemKey || 0) || undefined);
  };

  if (!(selectedPot instanceof VolumePot)) {
    throw new Error('VolumePot cast failed');
  }

  return (
    <div className={'customization'}>
      <ProfilePotPivot
        currentProfile={currentProfile}
        selectedPotId={selectedPotId}
        onPotChange={onPotChange}
      />
      {!deviceInfo.model.isRgbAvailable && (
        <InfoBox
          text={
            <>
              <span>{`Your device doesn't support RGB mode.`}</span>
              <br />
              <span
                style={{ fontSize: '0.9rem', opacity: 0.7 }}
              >{`Only web-interface visual changes available.`}</span>
            </>
          }
        />
      )}
      <ComboBoxComponent
        label={'Color mode'}
        selectedKey={selectedPot.customization.getCustomizationColorModeKey()}
        options={customizationColorModeList}
        onChange={(_, option) => {
          if (option?.key)
            selectedPot.customization.setMode(
              CustomizationColorModeEnum[option.key as keyof typeof CustomizationColorModeEnum],
            );
        }}
      />
      <div className={'customization-color-picker'}>
        {selectedPot.customization.mode === CustomizationColorModeEnum.Gradient && (
          <>
            <div
              className={'gradient-preview'}
              style={{ background: selectedPot.customization.getBackground(100) }}
            />
            <ColorPickerWithLabel
              label={'First color'}
              color={selectedPot.customization.gradient.startColor.toString()}
              onChange={(color) => {
                selectedPot.customization.gradient.startColor.setColor(color);
              }}
            />
            <ColorPickerWithLabel
              label={'Second color'}
              color={selectedPot.customization.gradient.endColor.toString()}
              onChange={(color) => {
                selectedPot.customization.gradient.endColor.setColor(color);
              }}
            />
          </>
        )}
        {selectedPot.customization.mode === CustomizationColorModeEnum.Solid && (
          <ColorPickerWithLabel
            label={'Color'}
            color={selectedPot.customization.color.toString()}
            onChange={(color) => {
              selectedPot.customization.color.setColor(color);
            }}
          />
        )}
        {selectedPot.customization.mode === CustomizationColorModeEnum.Breathe && (
          <>
            <ColorPickerWithLabel
              label={'Color'}
              color={selectedPot.customization.gradient.endColor.toString()}
              onChange={(color) => {
                selectedPot.customization.gradient.endColor.setColor(color);
              }}
            />
            Rate slider?
          </>
        )}
        {selectedPot.customization.mode === CustomizationColorModeEnum.Cycle && (
          <div>Not implemented</div>
        )}
      </div>
    </div>
  );
});
