import { IconButton, PrimaryButton } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ToastTypeEnum } from '../../../constants/ToastTypeEnum';
import { useServices } from '../../../hooks/useServices';
import { ToastWithButton } from '../../../models/ToastWithButton';
import './ToastNotification.scss';

export const ToastNotification = observer(
  (): JSX.Element => {
    const { globalStore } = useServices();
    const { toastList, hideToast } = globalStore.resolve();

    const getToastStyle = (type: ToastTypeEnum): string => {
      switch (type) {
        case ToastTypeEnum.Success:
          return 'toast-success';
        case ToastTypeEnum.Info:
          return 'toast-info';
        case ToastTypeEnum.Warning:
          return 'toast-warning';
        case ToastTypeEnum.Error:
          return 'toast-error';
        default:
          return '';
      }
    };

    const hideToastHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      const id = event.currentTarget.getAttribute('data-id');
      if (!id) return;
      hideToast(id, true);
    };

    return (
      <TransitionGroup className="toast-notification-area" timeout={200}>
        {toastList.map((toast) => (
          <CSSTransition key={toast.id} timeout={500} classNames="toast-notification">
            <div
              onMouseOver={() => (toast.isMouseOver = true)}
              onMouseLeave={() => (toast.isMouseOver = false)}
              key={toast.id}
              className={`toast-notification ${getToastStyle(toast.type)}`}
            >
              {!toast.isControlsHidden && (
                <div className={'toast-notification-controls'}>
                  <IconButton
                    iconProps={{ iconName: 'Cancel' }}
                    data-id={toast.id}
                    onClick={hideToastHandler}
                  />
                </div>
              )}
              <div className={'toast-notification-body'}>
                <span>{toast.message}</span>
                {toast instanceof ToastWithButton && (
                  <div className={'toast-button-container'}>
                    <PrimaryButton text={toast.buttonText} onClick={toast.onButtonClick} />
                  </div>
                )}
              </div>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  },
);
