import { ButtonGrid, IconButton, Stack, Text } from '@fluentui/react';
import { Card } from '@uifabric/react-cards';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useHistory } from 'react-router';
import { useServices } from '../../../../hooks/useServices';
import { Profile } from '../../../../models/Profile';
import { CardContainer } from '../../../shared/CardContainer/CardContainer';
import { VolumePotLabel } from '../../../shared/VolumePotLabel/VolumePotLabel';

interface IProfileCardProps {
  profile: Profile;
  isSelected?: boolean;
}

export const ProfileCard: React.FC<IProfileCardProps> = observer((props: IProfileCardProps) => {
  const { profile, isSelected = false } = props;
  const { removeProfile } = useServices().dataStore.resolve();
  const history = useHistory();

  const onProfileClick = (): void => history.push(`/profiles/${profile.id}`);

  const onRemoveProfileClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    removeProfile(profile.id);
  };

  return (
    <CardContainer className={isSelected ? 'selected' : undefined} onClick={onProfileClick}>
      <Card.Section>
        <Stack horizontalAlign="space-between" verticalAlign="end" horizontal>
          <Stack.Item
            styles={{
              root: {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              },
            }}
            grow
          >
            <Text variant="xxLarge">{profile.name}</Text>
          </Stack.Item>
          <Stack.Item className={'profile-remove-button-container'}>
            <IconButton iconProps={{ iconName: 'Delete' }} onClick={onRemoveProfileClick} />
          </Stack.Item>
        </Stack>
      </Card.Section>
      <Card.Item styles={{ root: { margin: 0 } }}>
        <ButtonGrid
          columnCount={2}
          items={profile.volumePotList}
          onRenderItem={(pot) => <VolumePotLabel key={pot.id} volumePot={pot} />}
          styles={{ root: { width: '100%' } }}
        />
      </Card.Item>
    </CardContainer>
  );
});
