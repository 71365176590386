export type ILocalStorageInternalTypes =
  | { object: Record<string, ILocalStorageCommonTypes> }
  | { string: string }
  | { boolean: boolean }
  | { array: ILocalStorageArrayType }
  | { number: number };

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type ILocalStorageCommonTypes =
  | string
  | boolean
  | number
  | undefined
  | Record<string, ILocalStorageCommonTypes>;

export type ILocalStorageArrayType = ILocalStorageCommonTypes[];
export type ILocalStorageTypes = ILocalStorageCommonTypes | ILocalStorageArrayType;

export type ILocalStorageFunctionType<T extends ILocalStorageTypes> = (storedValue: T) => T;

export class LocalStorage {
  public static get = <T extends ILocalStorageTypes>(key: string, defaultValue: T): T => {
    const item = window.localStorage.getItem(key);
    if (item) {
      try {
        const validObject = JSON.parse(item) as ILocalStorageInternalTypes;
        return LocalStorage.getItem(validObject, defaultValue);
      } catch (e) {
        return defaultValue;
      }
    }
    return defaultValue;
  };

  public static set = <T extends ILocalStorageTypes>(key: string, item: T): void => {
    const storageObject = LocalStorage.getStorageObject(item);
    window.localStorage.setItem(key, JSON.stringify(storageObject));
  };

  private static readonly getItem = <T extends ILocalStorageTypes>(
    validObject: ILocalStorageInternalTypes,
    defaultValue: T,
  ): T => {
    if (
      'object' in validObject &&
      (!defaultValue || typeof validObject.object === typeof defaultValue)
    ) {
      return validObject.object as T;
    } else if (
      'string' in validObject &&
      (!defaultValue || typeof validObject.string === typeof defaultValue)
    ) {
      return validObject.string as T;
    } else if (
      'boolean' in validObject &&
      (!defaultValue || typeof validObject.boolean === typeof defaultValue)
    ) {
      return validObject.boolean as T;
    } else if (
      'array' in validObject &&
      (!defaultValue || typeof validObject.array === typeof defaultValue)
    ) {
      return validObject.array as T;
    } else if (
      'number' in validObject &&
      (!defaultValue || typeof validObject.number === typeof defaultValue)
    ) {
      return validObject.number as T;
    }
    return defaultValue;
  };

  private static readonly getStorageObject = <T extends ILocalStorageTypes>(item: T) => {
    let storageObject: ILocalStorageInternalTypes;
    if (typeof item === 'object' && !Array.isArray(item)) {
      storageObject = { object: item as Record<string, ILocalStorageCommonTypes> };
    } else if (typeof item === 'string') {
      storageObject = { string: item };
    } else if (typeof item === 'boolean') {
      storageObject = { boolean: item };
    } else if (Array.isArray(item)) {
      storageObject = { array: item };
    } else if (typeof item === 'number' && !isNaN(item)) {
      storageObject = { number: item };
    } else {
      throw new Error('Type Error');
    }
    return storageObject;
  };
}
