import React, { useCallback, useEffect } from 'react';
import { ToastTypeEnum } from '../../../constants/ToastTypeEnum';
import { useServices } from '../../../hooks/useServices';
import { ToastWithButton } from '../../../models/ToastWithButton';
import * as serviceWorker from '../../../serviceWorker';

interface IServiceWorkerWrapperProps {
  children?: React.ReactElement;
}

export const ServiceWorkerWrapper: React.FC<IServiceWorkerWrapperProps> = (props) => {
  const { children } = props;
  const { globalStore } = useServices();
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  const addUpdateToast = useCallback(() => {
    globalStore.resolve().addToast(
      new ToastWithButton({
        isControlsHidden: true,
        timeAlive: null,
        type: ToastTypeEnum.Success,
        message: 'New version available!',
        buttonText: 'Update',
        onButtonClick: (toast) => {
          waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
          globalStore.resolve().hideToast(toast.id);
          window.location.reload();
        },
      }),
    );
  }, [globalStore, waitingWorker]);

  const onSWUpdate = useCallback(
    (registration: ServiceWorkerRegistration) => {
      setWaitingWorker(registration.waiting);
      addUpdateToast();
    },
    [addUpdateToast],
  );

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, [onSWUpdate]);

  return children ?? null;
};
