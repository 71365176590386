import { createHash } from 'crypto';

export class ObjectHash {
  public static md5 = <T extends Record<string, unknown>>(object: T): string =>
    ObjectHash.getHash('md5', JSON.stringify({ object }));

  public static sha1 = <T extends Record<string, unknown>>(object: T): string =>
    ObjectHash.getHash('sha1', JSON.stringify({ object }));

  private static readonly getHash = <T extends string>(algorithm: string, object: T): string =>
    createHash(algorithm).update(object).digest('hex');
}
