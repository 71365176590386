import { Label, List, ScrollablePane, ScrollbarVisibility, TextField } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React, { FormEvent, useMemo, useState } from 'react';
import { IApplication } from '../../../types/IApplication';
import './ProcessList.scss';

interface IProcessListProps {
  label: string;
  applicationList: IApplication[];
  isSearchable?: boolean;
  selectedApplication: IApplication | undefined;

  onSelectedApplicationChange: (application: IApplication) => void;
  onApplicationDoubleClick: () => void;
}

export const ProcessList: React.FC<IProcessListProps> = observer((props: IProcessListProps) => {
  const {
    label,
    applicationList,
    isSearchable = false,
    selectedApplication,
    onSelectedApplicationChange,
    onApplicationDoubleClick,
  } = props;

  const [filterQuery, setFilterQuery] = useState<string | undefined>();

  const onFilterChanged = (
    _: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    query: string | undefined,
  ): void => {
    setFilterQuery(query || undefined);
  };

  const appList = useMemo(
    () =>
      filterQuery
        ? applicationList.filter((application) =>
            application.processName.toLowerCase().includes(filterQuery.toLowerCase()),
          )
        : applicationList,
    [applicationList, filterQuery],
  );

  const resultCountText = useMemo(
    () =>
      appList.length === applicationList.length
        ? ''
        : ` (${appList.length} of ${applicationList.length} shown)`,
    [applicationList, appList],
  );

  const onRenderCell = (application: IApplication | undefined): React.ReactNode => {
    const isSelected =
      !!application && selectedApplication?.processName === application?.processName;
    return (
      <div
        className={`process-list-item${isSelected ? ' selected' : ''}`}
        onClick={() => {
          if (application) {
            onSelectedApplicationChange(application);
          }
        }}
        onDoubleClick={onApplicationDoubleClick}
      >
        {application?.processName}
      </div>
    );
  };

  return (
    <div className={'process-container'}>
      {isSearchable ? (
        <TextField
          label={`Filter ${label.toLocaleLowerCase()} by name ${resultCountText}`}
          onChange={onFilterChanged}
        />
      ) : (
        <Label>{label}</Label>
      )}
      <div className={'process-list'}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <List items={[...appList]} onRenderCell={onRenderCell} />
        </ScrollablePane>
      </div>
    </div>
  );
});
