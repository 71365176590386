import React from 'react';
import './InfoBox.scss';

interface IInfoBoxProps {
  text: React.ReactNode;
}

export const InfoBox: React.FC<IInfoBoxProps> = (props: IInfoBoxProps) => {
  const { text } = props;
  return (
    <div className={'info-box'}>
      <p style={{ textAlign: 'center' }}>
        <span>Info: </span>
        {text}
      </p>
    </div>
  );
};
