import { Slider } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { CustomizationColorModeEnum } from '../../../constants/Customization';
import { useServices } from '../../../hooks/useServices';
import { useThrottle } from '../../../hooks/useThrottle';
import { Customization } from '../../../models/Customization';
import { VolumePot } from '../../../models/VolumePot';
import { IVolumePotBase } from '../../../types/IVolumePotBase';
import { VolumePotLabel } from '../VolumePotLabel/VolumePotLabel';
import './VolumeBar.scss';

export interface IVolumeComponentProps {
  volumePot: IVolumePotBase;
  isMaster?: boolean;
}

export const VolumeBar: React.FC<IVolumeComponentProps> = observer(
  (props: IVolumeComponentProps) => {
    const { volumePot, isMaster = false } = props;
    const { isTesting } = useServices().globalStore.resolve();
    const { measurements } = useServices().measurementStore.resolve();
    const potMeasurements = measurements.getMeasurementsByPotId(volumePot.id);
    const potVolume = potMeasurements?.volume ?? 0;

    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
      setTimeout(() => {
        setInitialized(true);
      }, 50);
    }, []);
    const volume = initialized ? potVolume : 0;

    const customization =
      volumePot instanceof VolumePot
        ? volumePot.customization
        : new Customization({
            mode: isMaster ? CustomizationColorModeEnum.Gradient : CustomizationColorModeEnum.Solid,
          });

    const sliderStyle = () => ({
      activeSection: {
        background: customization.getSlider(),
      },
      inactiveSection: {
        background: 'rgba(255, 255, 255, 0.4)',
        opacity: 0.4,
        zIndex: -1,
      },
      thumb: {
        background: volume > 0 ? customization.getThumb() : '#838383',
      },
    });

    const randomVolume = isTesting
      ? useThrottle(() => potMeasurements?.randomVolume(), 100)
      : undefined;

    return (
      <div className={'volume-bar-component'} onClick={randomVolume}>
        <div
          className={'volume-bar-gradient'}
          style={{ background: customization.getBackground(volume) }}
        />
        <Slider
          styles={sliderStyle}
          value={volume}
          min={0}
          max={100}
          step={1}
          showValue={false}
          vertical
          disabled
        />
        <VolumePotLabel volumePot={volumePot} />
      </div>
    );
  },
);
