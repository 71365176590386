import React, { CSSProperties } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { ILinkItem } from '../../../types/ILinkItem';
import './Pivot.scss';

interface IPivotProps {
  options: ILinkItem[];

  className?: string;
  style?: CSSProperties;
}

export const Pivot: React.FC<IPivotProps> = (props) => {
  const { options, className, style } = props;
  const { path } = useRouteMatch();
  return (
    <div className={`pivot${className ? ` ${className}` : ''}`} style={style}>
      {options.map((item) => (
        <NavLink
          key={item.title}
          exact={item.link === ''}
          to={`${path}${item.link ?? `/${item.title.toLowerCase()}`}`}
        >
          <span>{item.title}</span>
        </NavLink>
      ))}
    </div>
  );
};
