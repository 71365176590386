import React from 'react';

interface ILayoutContainerProps {
  title: React.ReactNode;
  children: React.ReactNode;
  id?: string;
}

export const LayoutContainer: React.FC<ILayoutContainerProps> = (props) => {
  const { id, title, children } = props;

  return (
    <div className={`layout-container ${id?.toLowerCase() || ''}`}>
      <h1>{title}</h1>
      {children}
    </div>
  );
};
