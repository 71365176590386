import { ComboBox, IComboBoxProps } from '@fluentui/react';
import React from 'react';

export const ComboBoxComponent: React.FC<IComboBoxProps> = (props: IComboBoxProps) => {
  return (
    <ComboBox
      {...props}
      useComboBoxAsMenuWidth
      style={{ minWidth: '220px', maxWidth: '250px', opacity: 0.8 }}
    />
  );
};
