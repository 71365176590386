import { ApiSendActionTypes } from '../constants/ApiReducer/Actions';
import * as type from '../constants/ApiReducer/Constants';
import { LogLevel } from '../constants/LogLevel';
import { IProfile } from '../types/IProfile';
import { ISettings } from '../types/ISettings';
import { Logger } from '../utils/Logger';
import { ServiceBase } from './ServiceBase';
import { ServiceProvider } from './ServiceProvider';

export class SendService extends ServiceBase {
  public className = 'SendService';
  private readonly withConnection = this.serviceProvider.connectionService.resolve().withConnection;

  public constructor(serviceProvider: ServiceProvider) {
    super(serviceProvider);
  }

  public handShake = async (): Promise<void> => {
    try {
      return await this.withConnection(async (connection) => {
        await connection.send(`handshakeAsync`, process.env.REACT_APP_VERSION);
        Logger.log({
          logLevel: LogLevel.Debug,
          callerName: this.className,
          method: 'Handshake',
          message: 'Successfully sent',
        });
      });
    } catch (error) {
      Logger.log({
        logLevel: LogLevel.Critical,
        callerName: this.className,
        method: 'Handshake',
        message: `Send failed!\n${error.message}`,
        meta: error,
      });
    }
  };

  //region Action Creators
  public saveProfile = async (data: IProfile): Promise<void> =>
    this.dispatch({ actionName: type.SET_PROFILE, payload: data });

  public saveSelectedProfile = async (data: IProfile['id']): Promise<void> =>
    this.dispatch({ actionName: type.SET_SELECTED_PROFILE, payload: data });

  public saveSettings = async (data: ISettings): Promise<void> =>
    this.dispatch({ actionName: type.SET_USER_SETTINGS, payload: data });

  public createNewProfile = async (): Promise<void> =>
    this.dispatch({ actionName: type.CREATE_PROFILE });

  public removeProfile = async (data: IProfile['id']): Promise<void> =>
    this.dispatch({ actionName: type.REMOVE_PROFILE, payload: data });
  //endregion

  protected dispatch = async (action: ApiSendActionTypes): Promise<void> => {
    if (this.serviceProvider.globalStore.resolve().isTesting) return;
    try {
      return await this.withConnection(async (connection) => {
        await connection.send(`dispatchBackendAction`, JSON.stringify(action));
        Logger.log({
          logLevel: LogLevel.Debug,
          callerName: this.className,
          method: `${action.actionName}`,
          message: 'Successfully sent',
          meta: action,
        });
      });
    } catch (error) {
      Logger.log({
        logLevel: LogLevel.Error,
        callerName: this.className,
        method: `${action.actionName}`,
        message: `Send failed!\n${error.message}`,
      });
    }
  };
}
