import React from 'react';
import { Link } from 'react-router-dom';
import './BreadcrumbTitle.scss';

interface IBreadcrumbTitleProps {
  parent: {
    title: React.ReactNode;
    url: string;
  };
  children?: React.ReactNode;
}

export const BreadcrumbTitle: React.FC<IBreadcrumbTitleProps> = (props) => {
  const { parent, children } = props;

  return (
    <div className={'breadcrumb-container'}>
      <Link to={`/${parent.url}`}>{parent.title}</Link>
      <span className={'separator'}>{' > '}</span>
      <div className={'breadcrumb-children'}>{children}</div>
    </div>
  );
};
