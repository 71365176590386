import { useState } from 'react';

export const useThrottle = <CallbackArguments extends any[]>(
  callback: (...args: CallbackArguments) => void,
  delay: number,
): ((...args: CallbackArguments) => void) => {
  const [isThrottled, setThrottled] = useState<boolean>(false);

  return (...args: CallbackArguments) => {
    if (isThrottled) return;

    callback(...args);

    setThrottled(true);
    setTimeout(() => setThrottled(false), delay);
  };
};
