import React from 'react';
import './InfoTable.scss';

export interface IInfoGridTableItem {
  label: string;
  text: string;
}

interface IInfoGridTableProps {
  title: string;
  items: IInfoGridTableItem[];
}

export const InfoTable: React.FC<IInfoGridTableProps> = (props: IInfoGridTableProps) => {
  const { title, items } = props;
  return (
    <div className={'info-table'}>
      <h2>{title}</h2>
      <div className={'info-table-grid'}>
        {items.map((item) => (
          <div className={'info-table-row'} key={item.label}>
            <h4>{item.label}</h4>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
