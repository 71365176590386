import { Link } from '@fluentui/react';
import React from 'react';
import { AppInfo } from '../../models/AppInfo';
import { IInfoGridTableItem, InfoTable } from '../shared/GridTable/InfoTable';
import './About.scss';

export const AboutView: React.FC = () => {
  const tableData: IInfoGridTableItem[] = [
    {
      label: 'Edition',
      text: AppInfo.edition,
    },
    {
      label: 'Version',
      text: AppInfo.version,
    },
  ];

  return (
    <div className={'about'}>
      <InfoTable title={'Software specification'} items={tableData} />
      <div className={'about-feedback'}>
        <h2>Feedback</h2>
        <div className={'about-feedback-links'}>
          <Link href={'#'}>Report a problem</Link>
          <Link href={'#'}>Suggest a feature</Link>
          <Link href={'#'}>Get help</Link>
          <Link href={'#'}>Changelog</Link>
        </div>
      </div>
    </div>
  );
};
