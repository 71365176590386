export enum LanguageEnum {
  English,
  Russian,
}

export enum AppUpdatePeriodEnum {
  Never,
  Daily,
  Week,
  Month,
}

export enum OverlayCornerPositionEnum {
  TopLeft,
  TopRight,
  BottomRight,
  BottomLeft,
  Custom,
}
