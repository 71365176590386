import { IDeviceBlueprint } from '../types/IDeviceBlueprint';

export class DeviceBlueprint implements IDeviceBlueprint {
  public modelName: string;
  public potCount: number;
  public isRgbAvailable: boolean;
  public unitCode: number | null;

  public constructor(deviceBlueprint: IDeviceBlueprint) {
    this.modelName = deviceBlueprint.modelName;
    this.potCount = deviceBlueprint.potCount;
    this.isRgbAvailable = deviceBlueprint.isRgbAvailable;
    this.unitCode = deviceBlueprint.unitCode;
  }
}
