import debounce from 'lodash.debounce';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { IColor } from '../types/IColor';

export class Color implements IColor {
  public constructor(r: number, g: number, b: number, a?: number) {
    makeObservable<Color, '_r' | '_g' | '_b' | '_a'>(this, {
      _r: observable,
      r: computed,
      _g: observable,
      g: computed,
      _b: observable,
      b: computed,
      _a: observable,
      a: computed,
    });

    this.validateColor(r, g, b, a);
    this._r = r;
    this._g = g;
    this._b = b;
    this._a = a ?? 1;
  }

  private _r: number;
  public get r(): number {
    return this._r;
  }

  private _g: number;
  public get g(): number {
    return this._g;
  }

  private _b: number;
  public get b(): number {
    return this._b;
  }

  private _a: number;
  public get a(): number {
    return this._a;
  }

  public toString(): string {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
  }

  public asBackground = (stopValue = 0): string => {
    return `linear-gradient(4deg, ${this.toString()} 0%, ${this.toString()} ${stopValue}%, rgba(0,0,0,0) ${
      stopValue === 0 ? 0 : stopValue <= 90 ? stopValue + 10 : 100
    }%)`;
  };

  private readonly validateColor = (r: number, g: number, b: number, a = 1): void => {
    if (r < 0 || g < 0 || b < 0 || r > 255 || g > 255 || b > 255) {
      throw new Error('Expected value in range 0...255');
    }
    if (a < 0 || a > 1) {
      throw new Error('Expected alpha value in range 0...1');
    }
  };

  public setColor = debounce(
    ({ r, g, b, a }: { r: number; g: number; b: number; a?: number }): void => {
      runInAction(() => {
        this.validateColor(r, g, b);
        this._r = r;
        this._g = g;
        this._b = b;
        this._a = a ?? 1;
      });
    },
    250,
  );
}
