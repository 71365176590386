import { CommandBarButton } from '@fluentui/react';
import React from 'react';
import { useServices } from '../../../hooks/useServices';
import { menuItemList } from '../../../Routes';
import './Menu.scss';
import { MenuItem } from './MenuItem/MenuItem';

export const Menu: React.FC = () => {
  const { isTesting, isMenuCollapsed, toggleMenuCollapsed } = useServices().globalStore.resolve();
  const { model } = useServices().sideStore.resolve().deviceInfo;

  return (
    <div className={`menu${isMenuCollapsed ? ' menu-collapsed' : ''}`}>
      <div className={'menu-header menu-item'}>
        <CommandBarButton
          style={{ width: '48px', justifyContent: 'center' }}
          iconProps={{ iconName: 'GlobalNavButton' }}
          onClick={toggleMenuCollapsed}
        />
      </div>
      <div className={'menu-items'}>
        {menuItemList.map((menuItem) => (
          <MenuItem
            key={menuItem.title}
            icon={menuItem.icon}
            link={menuItem.link ?? `/${menuItem.title.toLowerCase()}`}
            isDisabled={
              !isTesting &&
              (menuItem.isDisabled ||
                (menuItem.deviceLimitations && !menuItem.deviceLimitations(model)))
            }
          >
            {menuItem.title}
          </MenuItem>
        ))}
      </div>
    </div>
  );
};
