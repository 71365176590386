import { action, makeObservable, observable } from 'mobx';
import { IApplication } from '../types/IApplication';
import { IVolumePot } from '../types/IVolumePot';
import { Customization } from './Customization';

export class VolumePot implements IVolumePot {
  public id: number;
  public name: string;
  public appList: IApplication[];
  public customization: Customization;

  public constructor(volumePot: IVolumePot) {
    this.id = volumePot.id;
    this.name = volumePot.name;

    makeObservable(this, {
      appList: observable,
      customization: observable,
      addApplication: action,
      removeApplication: action,
    });

    const { appList, customization } = volumePot;
    this.appList = appList ?? [];
    this.customization = new Customization(customization);
  }

  public addApplication = (application: IApplication): void => {
    if (!this.appList.includes(application)) {
      this.appList = [...this.appList, application];
    }
  };

  public removeApplication = (application: IApplication): void => {
    this.appList = [...this.appList.filter((app) => app.processName !== application.processName)];
  };
}
