import { ApplicationListStore } from '../stores/ApplicationListStore';
import { DataStore } from '../stores/DataStore';
import { GlobalStore } from '../stores/GlobalStore';
import { MeasurementStore } from '../stores/MeasurementStore';
import { SettingsStore } from '../stores/SettingsStore';
import { SideStore } from '../stores/SideStore';
import { ConnectionService } from './ConnectionService';
import { DataProviderService } from './DataProviderService';
import { LoadValidationService } from './LoadValidationService';
import { ReceiveService } from './ReceiveService';
import { SendService } from './SendService';
import { ServiceBase } from './ServiceBase';
import { ServiceInitializer } from './ServiceInitializer';

export class ServiceProvider {
  public constructor() {
    this.connectionService.resolve().connect();
  }

  private readonly addSingleton = <T extends ServiceBase>(
    service: (serviceProvider: ServiceProvider) => T,
  ) => {
    return new ServiceInitializer<T>(this, service);
  };

  public connectionService = this.addSingleton(
    (sp) => new ConnectionService(sp, 'http://127.0.0.1:8899/main'),
  );
  public sendService = this.addSingleton((sp) => new SendService(sp));
  public receiveService = this.addSingleton((sp) => new ReceiveService(sp));
  public loadValidationService = this.addSingleton((sp) => new LoadValidationService(sp));

  public measurementStore = this.addSingleton((sp) => new MeasurementStore(sp));
  public dataProvider = this.addSingleton((sp) => new DataProviderService(sp));
  public globalStore = this.addSingleton((sp) => new GlobalStore(sp));
  public sideStore = this.addSingleton((sp) => new SideStore(sp));
  public dataStore = this.addSingleton((sp) => new DataStore(sp));
  public applicationListStore = this.addSingleton((sp) => new ApplicationListStore(sp));
  public settingsStore = this.addSingleton((sp) => new SettingsStore(sp));
}

export const serviceProvider = new ServiceProvider();
