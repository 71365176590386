import { action, makeObservable, observable } from 'mobx';
import { Measurements } from '../models/Measurements';
import { ServiceProvider } from '../services/ServiceProvider';
import { IMeasurements } from '../types/IMeasurements';
import { StoreBase } from './StoreBase';

export class MeasurementStore extends StoreBase {
  public className = 'MeasurementStore';
  //region Properties

  public measurements: Measurements;

  //endregion

  public constructor(serviceProvider: ServiceProvider) {
    super(serviceProvider);

    makeObservable(this, {
      measurements: observable,
      updateMeasurements: action,
    });

    this.measurements = new Measurements(serviceProvider.dataProvider.resolve().getMeasurements());
  }

  public updateMeasurements = (data: IMeasurements): void => {
    this.measurements = new Measurements(data);
  };
}
