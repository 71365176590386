import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AboutView } from './components/About/AboutView';
import { CustomizationView } from './components/Customization/CustomizationView';
import { HomeView } from './components/Home/HomeView';
import { Layout } from './components/Layout/Layout';
import { PlatformView } from './components/Platform/PlatformView';
import { Profiles } from './components/Profiles/Profiles';
import { ProfileSettings } from './components/Profiles/ProfileSettings/ProfileSettings';
import { SettingsView } from './components/Settings/SettingsView';
import { LayoutContainer } from './components/shared/LayoutContainer/LayoutContainer';
import { ToastNotification } from './components/shared/ToastNotification/ToastNotification';
import { useAutoSave } from './hooks/useAutoSave';
import { useServices } from './hooks/useServices';
import { ILinkItem } from './types/ILinkItem';

export const menuItemList: ILinkItem[] = [
  {
    link: '/',
    title: 'Home',
    component: <HomeView />,
    icon: 'Home',
  },
  {
    title: 'Profiles',
    component: <Profiles />,
    icon: 'Contact',
    isExact: true,
  },
  {
    title: 'Customization',
    component: <CustomizationView />,
    icon: 'CRMCustomerInsightsApp',
    // isDisabled: true,
    // deviceLimitations: (device: DeviceBlueprint): boolean => device.isRgbAvailable,
  },
  {
    title: 'Platform',
    component: <PlatformView />,
    icon: 'Puzzle',
  },
  {
    title: 'About',
    component: <AboutView />,
    icon: 'Info',
  },
  {
    title: 'Settings',
    component: <SettingsView />,
    icon: 'Settings',
  },
];

const ReactHint = ReactHintFactory(React);

export const Routes: React.FC = observer(() => {
  const { globalStore, settingsStore, dataStore } = useServices();
  const { isTesting } = globalStore.resolve();
  useAutoSave(settingsStore, ['settings']);
  useAutoSave(dataStore, ['profileList', 'currentProfile']);

  return (
    <Router>
      <ReactHint autoPosition events delay={{ show: 500, hide: 150 }} />
      <Switch>
        <Layout>
          {menuItemList
            .filter((menuItem) => !menuItem.isDisabled || isTesting)
            .map((menuItem) => (
              <Route
                key={menuItem.title}
                exact={menuItem.link === '/' || menuItem.isExact}
                path={`${menuItem.link ?? `/${menuItem.title.toLowerCase()}`}`}
              >
                <LayoutContainer id={menuItem.title} title={menuItem.title}>
                  {menuItem.component}
                </LayoutContainer>
              </Route>
            ))}
          <Route path={'/profiles/:id'} component={ProfileSettings} />
        </Layout>
      </Switch>
      <ToastNotification />
    </Router>
  );
});
