export enum ConnectionStateEnum {
  Closed,
  Connecting,
  Reconnecting,
  Open,
  Broken,
}

export enum DataLoadStateEnum {
  Loading,
  Finished,
  Failed,
}
