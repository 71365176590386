import { action, computed, makeObservable, observable } from 'mobx';
import { ConnectionStateEnum, DataLoadStateEnum } from '../constants/ConnectionStateEnum';
import { necessaryData } from '../constants/NecessaryData';
import { Toast } from '../models/Toast';
import { ServiceProvider } from '../services/ServiceProvider';
import { LocalStorage } from '../utils/LocalStorage';
import { StoreBase } from './StoreBase';

export class GlobalStore extends StoreBase {
  public className = 'GlobalStore';
  //region Properties

  //region Base logic
  private _isConnecting = true;
  private _isDataLoading = true;
  public get isLoading(): boolean {
    return this._isDataLoading || this._isConnecting;
  }

  public error: string | undefined;
  //endregion

  //region Menu
  public isMenuCollapsed = LocalStorage.get('isMenuCollapsed', false);
  //endregion

  //region Test mode
  private readonly env = process.env.REACT_APP_TEST_ENV;
  private previewMode = false;
  //endregion

  //region Toasts
  private readonly _toastList: Toast[] = [];

  public get toastList(): Toast[] {
    return this._toastList.filter((toast) => toast.isVisible);
  }

  public addToast = (toast: Toast): Toast['id'] => {
    if (this._toastList.length >= 10) this._toastList.shift();
    this._toastList.push(toast);
    if (toast.timeAlive === null) return toast.id;
    setTimeout(() => {
      this.hideToast(toast.id);
    }, toast.timeAlive);
    return toast.id;
  };

  public hideToast = (id: string, force = false): void => {
    const toast = this.toastList.find((toast) => toast.id === id);
    if (force && toast) {
      this._toastList.splice(this._toastList.indexOf(toast), 1);
    } else {
      toast?.setVisibility(false);
    }
  };
  //endregion

  //endregion

  public constructor(serviceProvider: ServiceProvider) {
    super(serviceProvider);

    makeObservable<GlobalStore, 'previewMode' | '_isConnecting' | '_isDataLoading' | '_toastList'>(
      this,
      {
        _isConnecting: observable,
        _isDataLoading: observable,
        isLoading: computed,
        error: observable,
        isMenuCollapsed: observable,
        previewMode: observable,
        _toastList: observable,
        isTesting: computed,
        toastList: computed,
        turnPreviewModeOn: action,
        setLoadingState: action,
        setConnectionState: action,
        setError: action,
        toggleMenuCollapsed: action,
        addToast: action,
        hideToast: action,
      },
    );

    this.serviceProvider.connectionService
      .resolve()
      .subscribe((connectionState) =>
        this.setConnectionState(connectionState !== ConnectionStateEnum.Open),
      );
    this.serviceProvider.loadValidationService
      .resolve()
      .subscribe((loadingState) =>
        this.setLoadingState(loadingState !== DataLoadStateEnum.Finished),
      );

    if (this.isTesting) {
      this.testLoadData();
    }
  }

  public get isTesting(): boolean {
    return this.env === 'test' || this.previewMode;
  }

  //region Methods

  //region Test mode
  public turnPreviewModeOn = (): void => {
    this.previewMode = true;
    this.serviceProvider.connectionService.resolve().connect();
    this.testLoadData();
  };

  private readonly testLoadData = (): void => {
    necessaryData.forEach((model) => {
      this.serviceProvider.loadValidationService.resolve().setLoadedData(model);
    });
  };
  //endregion

  //region Base logic
  public setLoadingState = (isLoading: boolean): void => {
    this._isDataLoading = isLoading;
  };

  public setConnectionState = (isLoading: boolean): void => {
    this._isConnecting = isLoading;
  };

  public setError = (errorMessage: string | undefined): void => {
    this.error = errorMessage;
  };
  //endregion

  //region Menu
  public toggleMenuCollapsed = (): void => {
    LocalStorage.set('isMenuCollapsed', !this.isMenuCollapsed);
    this.isMenuCollapsed = !this.isMenuCollapsed;
  };
  //endregion

  //endregion
}
