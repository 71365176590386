import { CommandBarButton } from '@fluentui/react';
import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import './MenuItem.scss';

interface IMenuItemProps {
  link: string;
  icon?: string;
  isDisabled?: boolean;
  children: React.ReactNode;
}

export const MenuItem: React.FC<IMenuItemProps> = (props: IMenuItemProps) => {
  const { link, icon, isDisabled, children } = props;

  const isActive = !!useRouteMatch({ path: link, exact: link === '/' });

  const renderMenuItem = !icon ? (
    <div>{children}</div>
  ) : (
    <CommandBarButton disabled={isDisabled} iconProps={{ iconName: icon }} checked={isActive}>
      <div className={'menu-item-title'}>{children}</div>
    </CommandBarButton>
  );

  return (
    <>
      {isDisabled ? (
        <div data-rh={'Disabled'} className={'menu-item disabled'}>
          {renderMenuItem}
        </div>
      ) : (
        <NavLink exact={link === '/'} to={link} className={'menu-item'}>
          {renderMenuItem}
        </NavLink>
      )}
    </>
  );
};
