import { observer } from 'mobx-react-lite';
import React from 'react';
import { useServices } from '../../hooks/useServices';
import { LoadingContainer } from '../shared/LoadingContainer/LoadingContainer';
import './Layout.scss';
import { Menu } from './Menu/Menu';

interface ILayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<ILayoutProps> = observer((props: ILayoutProps) => {
  const { isLoading, isMenuCollapsed } = useServices().globalStore.resolve();
  const { children } = props;

  return (
    <LoadingContainer isLoading={isLoading}>
      <div className={`layout${isMenuCollapsed ? ' collapsed' : ''}`}>
        <Menu />
        <div className={'layout-wrapper'}>
          <div className="container">{children}</div>
        </div>
      </div>
    </LoadingContainer>
  );
});
