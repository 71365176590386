import { makeObservable, observable } from 'mobx';
import { IOverlay } from '../types/IOverlay';
import { EditableModelBase } from './EditableModelBase';
import { OverlayPosition } from './OverlayPosition';

export class Overlay extends EditableModelBase implements IOverlay {
  public overlayPosition: OverlayPosition;
  public isVisible: boolean;
  public overlayMonitor: number;
  public opacity: number;
  public scale: number;

  public constructor(props: IOverlay) {
    super();

    makeObservable(this, {
      overlayPosition: observable,
      isVisible: observable,
      overlayMonitor: observable,
      opacity: observable,
      scale: observable,
    });

    this.overlayPosition = new OverlayPosition(props.overlayPosition);
    this.isVisible = props.isVisible;
    this.overlayMonitor = props.overlayMonitor;
    this.opacity = props.opacity;
    this.scale = props.scale;
  }

  public toApi = (): IOverlay => {
    return {
      overlayPosition: this.overlayPosition.toApi(),
      isVisible: this.isVisible,
      overlayMonitor: this.overlayMonitor,
      opacity: this.opacity,
      scale: this.scale,
    };
  };
}
