export const SET_PROFILES = 'SET_PROFILES';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_NEW_PROFILE = 'SET_NEW_PROFILE';
export const SET_SELECTED_PROFILE = 'SET_SELECTED_PROFILE';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_FIRST_DEVICE = 'SET_FIRST_DEVICE';
export const SET_APPLICATIONS = 'SET_APPLICATIONS';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';

export const ERROR_SERVER = 'ERROR_SERVER';
export const ERROR_DEVICE = 'ERROR_DEVICE';

export const CHANGED_POT = 'CHANGED_POT';
export const CHANGED_BUTTON = 'CHANGED_BUTTON';

export const DISCONNECTED_DEVICE = 'DISCONNECTED_DEVICE';
export const RECONNECTED_DEVICE = 'RECONNECTED_DEVICE';

export const CREATE_PROFILE = 'CREATE_PROFILE';
export const REMOVE_PROFILE = 'REMOVE_PROFILE';
