import { ApiActionTypes } from '../constants/ApiReducer/Actions';
import { ConnectionStateEnum, DataLoadStateEnum } from '../constants/ConnectionStateEnum';
import { LogLevel } from '../constants/LogLevel';
import { necessaryData } from '../constants/NecessaryData';
import { Logger } from '../utils/Logger';
import { ServiceProvider } from './ServiceProvider';
import { ServiceWithSubscription } from './ServiceWithSubscription';

export class LoadValidationService extends ServiceWithSubscription<DataLoadStateEnum> {
  public className = 'LoadValidationService';
  private loadedData: string[] = [];

  private _state: DataLoadStateEnum = DataLoadStateEnum.Loading;
  public get state(): DataLoadStateEnum {
    return this._state;
  }

  public constructor(serviceProvider: ServiceProvider) {
    super(serviceProvider);

    this.serviceProvider.connectionService.resolve().subscribe((connectionState) => {
      if (
        connectionState !== ConnectionStateEnum.Open &&
        (this._state !== DataLoadStateEnum.Loading || this.loadedData.length)
      ) {
        this.refreshLoadingState();
        this.serviceProvider.dataProvider.resolve().resetStores();
      }
    });
  }

  public setLoadedData = (key: ApiActionTypes['actionName']): void => {
    if (this._state === DataLoadStateEnum.Finished || this.loadedData.includes(key)) return;

    this.loadedData.push(key);

    if (this.validateLoadedData(necessaryData, this.loadedData)) {
      this._state = DataLoadStateEnum.Finished;
      this.broadcast(this._state);
      Logger.log({
        logLevel: LogLevel.Debug,
        callerName: this.className,
        method: 'Loading state',
        message: DataLoadStateEnum[this._state],
      });
    } else {
      this._state = DataLoadStateEnum.Loading;
    }
  };

  public refreshLoadingState = (): void => {
    this._state = DataLoadStateEnum.Loading;
    this.loadedData = [];
    Logger.log({
      logLevel: LogLevel.Debug,
      callerName: this.className,
      method: 'Loading state',
      message: DataLoadStateEnum[this._state],
    });
    this.broadcast(this._state);
  };

  private readonly validateLoadedData = (
    necessaryData: string[],
    loadedData: string[],
  ): boolean => {
    if (loadedData.length < necessaryData.length) return false;

    return necessaryData.every((item) => loadedData.includes(item));
  };
}
