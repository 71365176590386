import { action, makeObservable, observable } from 'mobx';
import { CustomizationColorModeEnum } from '../constants/Customization';
import { ICustomization } from '../types/ICustomization';
import { Color } from './Color';
import { LinearGradient } from './LinearGradient';

export class Customization implements ICustomization {
  public mode: CustomizationColorModeEnum;

  public color: Color;
  public gradient: LinearGradient;

  public constructor(customization?: ICustomization) {
    makeObservable(this, {
      mode: observable,
      setMode: action,
    });

    this.mode = customization?.mode ?? CustomizationColorModeEnum.Solid;

    const color = customization?.color;
    this.color = new Color(color?.r ?? 65, color?.g ?? 191, color?.b ?? 80, color?.a);

    this.gradient = new LinearGradient(
      customization?.gradient?.startColor ?? new Color(56, 119, 255),
      customization?.gradient?.endColor ?? new Color(65, 191, 80),
    );
  }

  public setMode = (modeKey: CustomizationColorModeEnum): void => {
    this.mode = modeKey;
  };

  public getCustomizationColorModeKey = (): string => {
    return (
      Object.keys(CustomizationColorModeEnum).find(
        (key) =>
          CustomizationColorModeEnum[key as keyof typeof CustomizationColorModeEnum] === this.mode,
      ) ?? Object.keys(CustomizationColorModeEnum)[0]
    );
  };

  public getBackground = (stopValue = 0): string => {
    switch (this.mode) {
      case CustomizationColorModeEnum.Gradient:
        return this.gradient.asBackground(stopValue);
      case CustomizationColorModeEnum.Solid:
      case CustomizationColorModeEnum.Cycle:
      default:
        return this.color.asBackground(stopValue);
    }
  };

  public getSlider = (): string => {
    switch (this.mode) {
      case CustomizationColorModeEnum.Gradient:
        return this.gradient.asSliderStyle();
      case CustomizationColorModeEnum.Solid:
      case CustomizationColorModeEnum.Cycle:
      default:
        return this.color.toString();
    }
  };

  public getThumb = (): string => {
    switch (this.mode) {
      case CustomizationColorModeEnum.Gradient:
        return this.gradient.endColor.toString();
      case CustomizationColorModeEnum.Solid:
      case CustomizationColorModeEnum.Cycle:
      default:
        return this.color.toString();
    }
  };
}
