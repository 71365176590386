export enum CustomizationColorModeEnum {
  Solid = 0,
  Gradient = 1,
  Breathe = 2,
  Cycle = 3,
}

export const customizationColorModeList: { key: string; text: string }[] = Object.keys(
  CustomizationColorModeEnum,
).reduce((a: Array<{ key: string; text: string }>, key: string | number): Array<{
  key: string;
  text: string;
}> => {
  const numberKey = Number(key);
  if (!isNaN(numberKey)) {
    switch (numberKey) {
      case 0:
        return [...a, { key: CustomizationColorModeEnum[numberKey], text: 'Solid color' }];
      case 1:
        return [...a, { key: CustomizationColorModeEnum[numberKey], text: 'Linear gradient' }];
      case 2:
        return [...a, { key: CustomizationColorModeEnum[numberKey], text: 'Breathe effect' }];
      case 3:
        return [...a, { key: CustomizationColorModeEnum[numberKey], text: 'Color cycle' }];
      default:
        return a;
    }
  }
  return a;
}, []);
