import { Card } from '@uifabric/react-cards';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import './CardContainer.scss';

interface ICardContainerProps {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;

  onClick?: () => void;
}

export const CardContainer: React.FC<ICardContainerProps> = observer(
  (props: ICardContainerProps) => {
    const { children, className, style, onClick } = props;

    return (
      <Card
        className={`profile-card${className ? ` ${className}` : ''}`}
        style={style}
        onClick={onClick}
      >
        {children}
      </Card>
    );
  },
);
