import { IColor } from '../types/IColor';
import { Color } from './Color';

export class LinearGradient {
  private readonly _startColor: Color;
  private readonly _endColor: Color;

  public constructor(startColor: IColor, endColor: IColor) {
    this._startColor = new Color(startColor.r, startColor.g, startColor.b, startColor.a);
    this._endColor = new Color(endColor.r, endColor.g, endColor.b, endColor.a);
  }

  public get startColor(): Color {
    return this._startColor;
  }

  public get endColor(): Color {
    return this._endColor;
  }

  public asBackground(stopValue = 0): string {
    if (stopValue < 0 || stopValue > 100) {
      throw new Error('Expected value in range 0...100');
    }

    return `linear-gradient(4deg, ${this.startColor.toString()} 0%, ${this.endColor.toString()} ${stopValue}%, rgba(0,0,0,0) ${
      stopValue === 0 ? 0 : stopValue <= 90 ? stopValue + 10 : 100
    }%)`;
  }

  public asSliderStyle(): string {
    return `linear-gradient(0deg, ${this.startColor.toString()} 0%, ${this.endColor.toString()} 100%)`;
  }
}
