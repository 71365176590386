import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ILinkItem } from '../../types/ILinkItem';
import { Pivot } from '../shared/Pivot/Pivot';
import { Appearance } from './Appearance/Appearance';
import { General } from './General/General';
import { Overlay } from './Overlay/Overlay';
import './Settings.scss';
import { Updates } from './Updates/Updates';

const settingsNavItems: ILinkItem[] = [
  {
    title: 'General',
    link: '',
    component: <General />,
  },
  {
    title: 'Updates',
    component: <Updates />,
  },
  {
    title: 'Appearance',
    component: <Appearance />,
  },
  {
    title: 'Overlay',
    component: <Overlay />,
  },
];

export const SettingsView: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Pivot options={settingsNavItems} />
      <div className={'settings-container'}>
        <Switch>
          {settingsNavItems.map((item) => (
            <Route
              key={item.title}
              exact={item.link === ''}
              path={`${path}${item.link ?? `/${item.title.toLowerCase()}`}`}
            >
              {item.component}
            </Route>
          ))}
        </Switch>
      </div>
    </>
  );
};
