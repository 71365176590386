import { observer } from 'mobx-react-lite';
import React from 'react';
import { useServices } from '../../../hooks/useServices';
import { VolumePot } from '../../../models/VolumePot';
import { VolumePotMaster } from '../../../models/VolumePotMaster';
import { IVolumePotBase } from '../../../types/IVolumePotBase';
import { DefaultLabel } from './DefaultLabel';
import { MasterLabel } from './MasterLabel';
import './VolumePotLabel.scss';

interface IVolumePotLabelProps {
  volumePot: IVolumePotBase;
}

export const VolumePotLabel: React.FC<IVolumePotLabelProps> = observer(
  (props: IVolumePotLabelProps) => {
    const { volumePot } = props;
    const { measurements } = useServices().measurementStore.resolve();
    const { name } = volumePot;

    return (
      <div className={'volume-bar-props'}>
        <div className={'label'}>{name}</div>
        {volumePot instanceof VolumePotMaster && <MasterLabel measurements={measurements} />}
        {volumePot instanceof VolumePot && <DefaultLabel appList={volumePot.appList} />}
      </div>
    );
  },
);
