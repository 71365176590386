import { Checkbox } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { AppUpdatePeriodEnum } from '../../../constants/Settings';
import { useServices } from '../../../hooks/useServices';
import { ComboBoxComponent } from '../../shared/ComboBox/ComboBoxComponent';

export const Updates: React.FC = observer(() => {
  const { settings } = useServices().settingsStore.resolve();
  const { appUpdatePeriod, isSubscribeToBetaUpdates, setProperty: set } = settings;

  return (
    <div>
      <ComboBoxComponent
        selectedKey={AppUpdatePeriodEnum[appUpdatePeriod]}
        label={'How often to check updates'}
        options={Object.keys(AppUpdatePeriodEnum)
          .filter(
            (k) => typeof AppUpdatePeriodEnum[k as keyof typeof AppUpdatePeriodEnum] === 'number',
          )
          .map((key) => ({ key: key, text: key }))}
        onChange={(e, value) =>
          set(
            'appUpdatePeriod',
            AppUpdatePeriodEnum[(value?.key as keyof typeof AppUpdatePeriodEnum) ?? 0],
          )
        }
      />
      <Checkbox
        checked={isSubscribeToBetaUpdates}
        label="Subscribe to Beta updates (not recommended)"
        onChange={(e, value) => set('isSubscribeToBetaUpdates', !!value)}
      />
    </div>
  );
});
