import { IDeviceInfo } from '../types/IDeviceInfo';
import { DeviceBlueprint } from './DeviceBlueprint';
import { DeviceConnection } from './DeviceConnection';

export class DeviceInfo implements IDeviceInfo {
  public model: DeviceBlueprint;
  public deviceId: string;
  public firmwareVersion: string;
  public latestUpdate: Date;
  public connectionInfo: DeviceConnection;

  public constructor(deviceInfo: IDeviceInfo) {
    this.model = new DeviceBlueprint(deviceInfo.model);
    this.deviceId = deviceInfo.deviceId;
    this.firmwareVersion = deviceInfo.firmwareVersion;
    this.latestUpdate = new Date(deviceInfo.latestUpdate);
    this.connectionInfo = new DeviceConnection(deviceInfo.connectionInfo);
  }

  public static get default(): IDeviceInfo {
    return {
      model: { modelName: '', potCount: 0, isRgbAvailable: false, unitCode: 0 },
      deviceId: '',
      firmwareVersion: '',
      latestUpdate: new Date('2020-07-29'),
      connectionInfo: { type: 0, address: '' },
    };
  }
}
