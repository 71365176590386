import { observer } from 'mobx-react-lite';
import { PrimaryButton } from '@fluentui/react';
import React from 'react';
import { DeviceConnectionTypeEnum } from '../../constants/DeviceInfo';
import { useServices } from '../../hooks/useServices';
import { IInfoGridTableItem, InfoTable } from '../shared/GridTable/InfoTable';
import './Platform.scss';

export const PlatformView: React.FC = observer(() => {
  const { deviceInfo } = useServices().sideStore.resolve();

  const onFirmwareUpdateClick = () => {
    console.debug('onFirmwareUpdateClick');
  };

  const tableData: IInfoGridTableItem[] = [
    {
      label: 'Device name',
      text: `VolumeMixer-${deviceInfo.model.modelName}`,
    },
    {
      label: 'Firmware type',
      text: deviceInfo.model.modelName,
    },
    {
      label: 'Firmware version',
      text: deviceInfo.firmwareVersion,
    },
    {
      label: 'Latest update',
      text: deviceInfo.latestUpdate.toLocaleDateString(),
    },
    {
      label: 'Connection',
      text: `[${DeviceConnectionTypeEnum[deviceInfo.connectionInfo.type]}] ${
        deviceInfo.connectionInfo.address
      }`,
    },
  ];

  return (
    <div className={'platform'}>
      <InfoTable title={'Hardware specification'} items={tableData} />
      <PrimaryButton text={'Check for firmware updates'} onClick={onFirmwareUpdateClick} />
    </div>
  );
});
