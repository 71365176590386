import { action, computed, makeObservable, observable } from 'mobx';
import { ToastTypeEnum } from '../constants/ToastTypeEnum';
import { IToastBase } from '../types/Toast';

export class Toast implements IToastBase {
  public id: string;
  public message: string;
  public type: ToastTypeEnum;
  public timeAlive: number | null;
  public isControlsHidden: boolean;

  private _isMouseOver = false;
  public get isMouseOver(): boolean {
    return this._isMouseOver;
  }
  public set isMouseOver(isOver: boolean) {
    this._isMouseOver = isOver;
    this.isVisible = !(!isOver && this.isStale);
  }

  public isStale = false;
  public isVisible: boolean;

  public constructor(toast: IToastBase) {
    makeObservable<Toast, '_isMouseOver'>(this, {
      isVisible: observable,
      _isMouseOver: observable,
      isMouseOver: computed,
      setVisibility: action,
    });

    this.id = Math.random().toString(36).substr(2, 9);
    this.message = toast.message;
    this.type = toast.type;
    this.isVisible = true;
    this.timeAlive =
      typeof toast.timeAlive === 'number' || toast.timeAlive === null
        ? toast.timeAlive
        : toast.type === ToastTypeEnum.Error
        ? 10000
        : 3000;
    this.isControlsHidden = toast.isControlsHidden ?? false;
  }

  public setVisibility = (isVisible = true): void => {
    if (!this.isMouseOver) this.isVisible = isVisible;
    this.isStale = true;
  };
}
