import { Checkbox } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { LanguageEnum } from '../../../constants/Settings';
import { useServices } from '../../../hooks/useServices';
import { ComboBoxComponent } from '../../shared/ComboBox/ComboBoxComponent';

export const General: React.FC = observer(() => {
  const { settings } = useServices().settingsStore.resolve();
  const {
    isLaunchOnStartup,
    isHideInTray,
    isStartCollapsed,
    language,
    isStoreConfigsPortable,
    setProperty: set,
  } = settings;

  return (
    <div>
      <Checkbox
        label="Launch Volume Mixer on startup"
        checked={isLaunchOnStartup}
        onChange={(e, value) => set('isLaunchOnStartup', !!value)}
      />
      <Checkbox
        label="Hide in tray when collapsed"
        checked={isHideInTray}
        onChange={(e, value) => set('isHideInTray', !!value)}
      />
      <Checkbox
        label="Start collapsed"
        checked={isStartCollapsed}
        onChange={(e, value) => set('isStartCollapsed', !!value)}
      />
      <ComboBoxComponent
        label={'Language'}
        selectedKey={LanguageEnum[language]}
        options={Object.keys(LanguageEnum)
          .filter((k) => typeof LanguageEnum[k as keyof typeof LanguageEnum] === 'number')
          .map((key) => ({ key: key, text: key }))}
        onChange={(e, value) =>
          set('language', LanguageEnum[(value?.key as keyof typeof LanguageEnum) ?? 0])
        }
      />
      <Checkbox
        label="Store configs in folder with program (portable)"
        checked={isStoreConfigsPortable}
        onChange={(e, value) => set('isStoreConfigsPortable', !!value)}
      />
    </div>
  );
});
