import { PivotItem } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useServices } from '../../../hooks/useServices';
import { IApplication } from '../../../types/IApplication';
import { LayoutContainer } from '../../shared/LayoutContainer/LayoutContainer';
import { ProfilePotPivot } from '../../shared/Pivot/ProfilePotPivot/ProfilePotPivot';
import { ProcessList } from '../ProcessList/ProcessList';
import { BreadcrumbProfileTitle } from './BreadcrumbProfileTitle/BreadcrumbProfileTitle';
import './ProfileSettings.scss';

export const ProfileSettings: React.FC = observer(() => {
  const { profileList } = useServices().dataStore.resolve();
  const { applicationList } = useServices().applicationListStore.resolve();

  const { id } = useRouteMatch<{ id: string }>().params;
  const profile = profileList.find((p) => p.id === id);
  if (!profile) return null;

  const [currentPotId, setCurrentPotId] = useState(profile.volumePotList[0].id);
  const selectedPot = useMemo(() => profile.volumePotList.find((pot) => pot.id === currentPotId), [
    currentPotId,
    profile.volumePotList,
  ]);

  const onPotChange = (item: PivotItem | undefined) => {
    const newId = +(item?.props?.itemKey || 0) || undefined;
    if (newId) setCurrentPotId(newId);
    setSelectedItem(undefined);
  };

  const appList = selectedPot?.appList;

  const filteredApplicationList = useMemo(
    () =>
      applicationList.filter(
        (application) => !appList?.some((app) => app.processName === application.processName),
      ),
    [applicationList, appList],
  );

  const [selectedItem, setSelectedItem] = useState<
    { listId: 1 | 2; application: IApplication } | undefined
  >();

  const onItemAdd = (): void => {
    const application = applicationList.find(
      (app) => app.processName === selectedItem?.application?.processName,
    );
    if (selectedPot && application) {
      if (selectedItem?.listId === 1) {
        selectedPot.removeApplication(application);
      } else if (selectedItem?.listId === 2) {
        selectedPot.addApplication(application);
      }
      setSelectedItem(undefined);
    }
  };

  return (
    <LayoutContainer title={<BreadcrumbProfileTitle profile={profile} />}>
      <div className={'profiles-grid'}>
        <ProfilePotPivot
          currentProfile={profile}
          selectedPotId={currentPotId}
          onPotChange={onPotChange}
          renderChildren={(pot) => (
            <ProcessList
              label={`${pot.name} process list`}
              applicationList={appList ?? []}
              selectedApplication={selectedItem?.application}
              onSelectedApplicationChange={(item) => {
                setSelectedItem({ listId: 1, application: item });
              }}
              onApplicationDoubleClick={onItemAdd}
            />
          )}
        />
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={onItemAdd}
        >
          <span
            style={{
              transform: 'rotateZ(90deg)',
            }}
          >
            Controls
          </span>
        </div>
        <ProcessList
          label={'Processes'}
          applicationList={filteredApplicationList}
          isSearchable={true}
          selectedApplication={selectedItem?.application}
          onSelectedApplicationChange={(item) => {
            setSelectedItem({ listId: 2, application: item });
          }}
          onApplicationDoubleClick={onItemAdd}
        />
      </div>
    </LayoutContainer>
  );
});
