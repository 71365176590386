import { LogLevel } from '../constants/LogLevel';
import { Logger } from '../utils/Logger';
import { ServiceBase } from './ServiceBase';
import { ServiceProvider } from './ServiceProvider';

export class ServiceInitializer<T extends ServiceBase> {
  public name = 'ServiceInitializer';
  public callback: (serviceProvider: ServiceProvider) => T;
  private _instance?: T;
  private _subscribers: (() => void)[] = [];

  public constructor(
    private readonly serviceProvider: ServiceProvider,
    callback: (serviceProvider: ServiceProvider) => T,
  ) {
    this.callback = callback;
  }

  public get isResolved(): boolean {
    return this._instance !== undefined;
  }

  public resolve = (): T => {
    if (!(this._instance instanceof ServiceBase)) {
      this._instance = this.callback(this.serviceProvider);
      Logger.log({
        logLevel: LogLevel.Debug,
        callerName: this.name,
        method: 'Resolved',
        message: this._instance.className,
      });
      this.notifySubscribers();
    }
    return this._instance;
  };

  public subscribe = (callback: () => void): number => this._subscribers.push(callback);

  private readonly notifySubscribers = (): void => {
    this._subscribers.forEach((callback) => callback());
    this._subscribers = [];
  };
}
