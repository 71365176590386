import { action, makeObservable, observable } from 'mobx';
import { ServiceProvider } from '../services/ServiceProvider';
import { IApplication } from '../types/IApplication';
import { StoreBase } from './StoreBase';

export class ApplicationListStore extends StoreBase {
  public className = 'ApplicationListStore';
  public applicationList: IApplication[];

  public constructor(serviceProvider: ServiceProvider) {
    super(serviceProvider);

    makeObservable(this, {
      applicationList: observable,
      updateApplicationList: action,
    });

    this.applicationList = this.serviceProvider.dataProvider.resolve().getApplicationList();
  }

  public updateApplicationList = (applicationList: IApplication[]): void => {
    this.applicationList = applicationList;
  };
}
