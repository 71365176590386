import { ISubscriptionCallback, IUnsubscriptionCallback } from '../types/IConnection';
import { ServiceBase } from './ServiceBase';

export abstract class ServiceWithSubscription<T> extends ServiceBase {
  private readonly subscribers: (ISubscriptionCallback<T> | undefined)[] = [];

  public subscribe = (callback: ISubscriptionCallback<T>): IUnsubscriptionCallback => {
    const id = this.subscribers.push(callback);
    return () => {
      this.subscribers[id] = undefined;
      return undefined;
    };
  };

  protected broadcast = (data: T): void => {
    this.subscribers.forEach((callback) => callback && callback(data));
  };
}
