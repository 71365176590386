import React from 'react';
import { IApplication } from '../../../types/IApplication';

interface IDefaultLabelProps {
  appList: IApplication[];
}

export const DefaultLabel: React.FC<IDefaultLabelProps> = (props: IDefaultLabelProps) => {
  const { appList } = props;
  const appCount = appList.length;

  return <div className={'default-label'}>{`${appCount} app${appCount === 1 ? '' : 's'}`}</div>;
};
