import { autorun } from 'mobx';
import { useEffect, useState } from 'react';
import { ServiceInitializer } from '../services/ServiceInitializer';
import { StoreWithSave } from '../stores/StoreWithSave';

export const useAutoSave = <T extends StoreWithSave>(
  service: ServiceInitializer<T>,
  deps: Array<keyof T>,
): void => {
  const [isResolved, setResolved] = useState(service.isResolved);

  useEffect(() => {
    if (!isResolved)
      service.subscribe(() =>
        setTimeout(() => {
          setResolved(true);
        }, 0),
      );

    let firstRun = true;
    if (!isResolved) return;

    autorun(() => {
      const resolved = service.resolve();

      deps.forEach((key) => {
        JSON.stringify(resolved[key]);
      });

      if (!firstRun) {
        resolved.save();
      }

      firstRun = false;
    });
  }, [deps, isResolved, service]);
};
