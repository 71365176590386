import { Checkbox, Slider } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useServices } from '../../../hooks/useServices';
import { ComboBoxComponent } from '../../shared/ComboBox/ComboBoxComponent';

export const Overlay: React.FC = observer(() => {
  const { settings } = useServices().settingsStore.resolve();
  const { isVisible, overlayMonitor, scale, opacity, overlayPosition, setProperty: set } = settings.overlay;

  return (
    <div>
      <Checkbox
        checked={isVisible}
        label="Show volume overlay on change"
        onChange={(e, value) => set('isVisible', !!value)}
      />
      <ComboBoxComponent
        selectedKey={overlayMonitor}
        label={'Monitor'}
        options={[]}
        onChange={(e, value) => set('overlayMonitor', +(value?.key ?? 0) ?? 0)}
      />
      <Slider
        value={scale}
        min={1}
        max={100}
        label={'Scale'}
        onChange={(value) => set('scale', value)}
      />
      <Slider
        value={opacity}
        min={1}
        max={100}
        label={'Opacity'}
        onChange={(value) => set('opacity', value)}
      />
    </div>
  );
});
