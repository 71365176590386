import { Pivot, PivotItem } from '@fluentui/react';
import React from 'react';
import { IProfile } from '../../../../types/IProfile';
import { IVolumePot } from '../../../../types/IVolumePot';
import './ProfilePotPivot.scss';

interface IProfilePotPivotProps {
  currentProfile?: IProfile;
  selectedPotId?: number;
  renderChildren?: (pot: IVolumePot) => React.ReactNode;

  onPotChange: (item: PivotItem | undefined) => void;
}

export const ProfilePotPivot: React.FC<IProfilePotPivotProps> = (props: IProfilePotPivotProps) => {
  const { currentProfile, selectedPotId, renderChildren, onPotChange } = props;

  const potList = currentProfile?.volumePotList ?? [];

  return (
    <div className={'profile-pot-pivot'}>
      <Pivot selectedKey={selectedPotId?.toString()} onLinkClick={onPotChange}>
        {potList.map((volumePot) => (
          <PivotItem
            className={'profiles-pivot-container'}
            key={volumePot.name}
            itemKey={volumePot.id.toString()}
            headerText={volumePot.name}
          >
            {!!renderChildren && renderChildren(volumePot)}
          </PivotItem>
        ))}
      </Pivot>
    </div>
  );
};
