import { Icon } from '@fluentui/react';
import { Card } from '@uifabric/react-cards';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CardContainer } from '../../../../shared/CardContainer/CardContainer';
import './EmptyCard.scss';

interface IEmptyCardProps {
  onCreateProfileClick: () => void;
}

export const EmptyCard: React.FC<IEmptyCardProps> = observer((props) => {
  const { onCreateProfileClick } = props;

  return (
    <CardContainer className={'empty'} onClick={onCreateProfileClick}>
      <Card.Item>
        <div className={'empty-card'}>
          <Icon iconName={'CirclePlus'} />
          <h1>{'Add new profile'}</h1>
        </div>
      </Card.Item>
    </CardContainer>
  );
});
