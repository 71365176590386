import { ApiActionTypes } from '../constants/ApiReducer/Actions';
import * as type from '../constants/ApiReducer/Constants';
import { LogLevel } from '../constants/LogLevel';
import { Logger } from '../utils/Logger';
import { DataProviderService } from './DataProviderService';
import { ServiceBase } from './ServiceBase';
import { ServiceProvider } from './ServiceProvider';

export class ReceiveService extends ServiceBase {
  public className = 'ReceiveService';
  private readonly withConnection = this.serviceProvider.connectionService.resolve().withConnection;

  public constructor(serviceProvider: ServiceProvider) {
    super(serviceProvider);
    this.initiateMonitoring();
  }

  private readonly initiateMonitoring = (): void => {
    const dataProvider = this.serviceProvider.dataProvider.resolve();

    try {
      this.withConnection((connection) => {
        connection.on(`dispatchUiAction`, (action: ApiActionTypes) => {
          this.reducer(dataProvider, action);
          this.serviceProvider.loadValidationService.resolve().setLoadedData(action.actionName);
        });
      });
    } catch (error) {
      Logger.log({
        logLevel: LogLevel.Error,
        callerName: this.className,
        method: 'ReceiveError',
        message: error,
      });
    }
  };

  protected reducer = (dataProvider: DataProviderService, action: ApiActionTypes): void => {
    Logger.log({
      logLevel: LogLevel.Debug,
      callerName: this.className,
      method: `API Received`,
      message: `${action.actionName}`,
      ...('payload' in action ? { meta: action.payload } : undefined),
    });
    switch (action.actionName) {
      case type.SET_PROFILES:
        dataProvider.updateProfileList(action.payload);
        break;
      case type.SET_NEW_PROFILE:
        dataProvider.addProfile(action.payload);
        break;
      case type.SET_SELECTED_PROFILE:
        dataProvider.updateSelectedProfile(action.payload);
        break;
      case type.SET_FIRST_DEVICE:
        dataProvider.updateDeviceInfo(action.payload);
        break;
      case type.SET_APPLICATIONS:
        dataProvider.updateApplicationList(action.payload);
        break;
      case type.SET_USER_SETTINGS:
        dataProvider.updateSettings(action.payload);
        break;
      case type.CHANGED_POT:
        dataProvider.updatePotVolume({
          potId: action.payload.eventSourceCode,
          volume: action.payload.value,
        });
        break;
      case type.CHANGED_BUTTON:
        dataProvider.updateButton(action.payload);
        break;
      case type.DISCONNECTED_DEVICE:
      case type.RECONNECTED_DEVICE:
      case type.SET_DEVICES:
      case type.ERROR_SERVER:
      case type.ERROR_DEVICE:
      default:
        Logger.log({
          logLevel: LogLevel.Information,
          callerName: this.className,
          method: 'Unsupported action',
          message: action.actionName,
        });
    }
  };
}
