import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route, Switch } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { ProfileList } from './ProfileList/ProfileList';

export const Profiles: React.FC = observer(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact={true} path={`${path}`}>
        <ProfileList />
      </Route>
    </Switch>
  );
});
