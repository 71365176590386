import { initializeIcons, loadTheme } from '@fluentui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ServiceWorkerWrapper } from './components/shared/ServiceWorkerWrapper/ServiceWorkerWrapper';
import './index.scss';

initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/');

loadTheme({
  palette: {
    themePrimary: '#0089f2',
    themeLighterAlt: '#00050a',
    themeLighter: '#001627',
    themeLight: '#002949',
    themeTertiary: '#005291',
    themeSecondary: '#0079d5',
    themeDarkAlt: '#1895f4',
    themeDark: '#3ba5f5',
    themeDarker: '#6dbcf8',
    neutralLighterAlt: '#282828',
    neutralLighter: '#313131',
    neutralLight: '#3f3f3f',
    neutralQuaternaryAlt: '#484848',
    neutralQuaternary: '#4f4f4f',
    neutralTertiaryAlt: '#6d6d6d',
    neutralTertiary: '#e2e2e2',
    neutralSecondary: '#e7e7e7',
    neutralPrimaryAlt: '#ebebeb',
    neutralPrimary: '#d4d4d4',
    neutralDark: '#f5f5f5',
    black: '#fafafa',
    white: '#1f1f1f',
  },
});

ReactDOM.render(
  <ServiceWorkerWrapper>
    <App />
  </ServiceWorkerWrapper>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
