import { makeObservable, observable } from 'mobx';
import { OverlayCornerPositionEnum } from '../constants/Settings';
import { IOverlayPosition } from '../types/IOverlay';
import { EditableModelBase } from './EditableModelBase';

export class OverlayPosition extends EditableModelBase implements IOverlayPosition {
  public corner: OverlayCornerPositionEnum;
  public xPosition: number;
  public yPosition: number;

  public constructor(props?: IOverlayPosition) {
    super();

    makeObservable(this, {
      corner: observable,
      xPosition: observable,
      yPosition: observable,
    });

    this.corner = props?.corner ?? OverlayCornerPositionEnum.TopLeft;
    this.xPosition = props?.xPosition ?? 0;
    this.yPosition = props?.yPosition ?? 0;
  }

  public toApi = (): IOverlayPosition => ({
    corner: this.corner,
    xPosition: this.xPosition,
    yPosition: this.yPosition,
  });
}
